// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // обнаруживает язык браузера
  .use(initReactI18next) // передает i18n в react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "googlePlayPurchasingIssues": {
            "title": "Issues with Payments on Google Play",
            "description1": "Due to changes in Google Play policies, we are forced to change jurisdiction to continue using the Google Play payment system. Since Google provided insufficient time for this, we did not have enough time to make the transition process seamless for our users. Therefore, starting from <b>December 26, 2024</b>, payments through the Google Play payment system will not work for some time. Also, all existing subscriptions that were created before this date and whose paid period has ended cannot be renewed until Google Play payments are resumed.",
            "description2": "We are currently working to <b>resume Google Play payments as soon as possible</b>. However, much of this issue is beyond our control, so it will take some time. We appreciate your understanding.",
            "description3": "If you have any questions or concerns, please contact us via email at <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Account",
          "Providers": "Login methods",
          "Logout": "Logout",
          "Enter promo title": "Promo codes",
          "Enter promo placeholder": "Enter promo code",
          "Enter promo button": "Apply",
          "Promocode activated title": "Promo code activated",
          "Promocode activated you received": "You received:",
          "Promocode activated you received Premium until": "Premium subscription until {{date}}",
          "Promocode not activated title": "Promo code activation error",
          "Promocode activation error unknown": "Unknown error",
          "Promocode activation error not found": "Promo code not found",
          "Promocode activation error already used": "Promo code has already been used",
          "Promocode activation error you already used": "You have already used this promo code",
          "Login methods title": "Login methods",
          "Login methods no data": "No login methods available",
          "Login methods add method": "Add login method",
          "Active subscription title": "Active subscriptions",
          "Active subscription active untill": "Next payment {{date}}",
          "Active subscription manage subscription": "Manage subscription",
          "Active subscription no subscriptions": "No active subscriptions",
          "Active subscription per month": "Per month",
          "Active subscription per year": "Per year",
          "Active subscription forever": "Forever",
          "Active subscription subscription": "Subscription",
          "Active subscription subscription title": "Premium subscription ({{type}})",
          "Active subscription no ads": "No ads",
          "Active subscription one-time purchase": "One-time purchase",
          "Active subscription promocode": "Promo code",
          "SubscriptionOptions title": "Get Premium",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} per month",
          "SubscriptionOptions price yearly": "{{price}} per year",
          "SubscriptionOptions price forever": "{{price}} forever",
          "SubscriptionOptions monthly subscription": "Monthly subscription",
          "SubscriptionOptions yearly subscription": "Yearly subscription",
          "SubscriptionOptions forever subscription": "One-time purchase",
          "SubscriptionOptions continue button": "Continue",
          "AuthMethods_welcome_title": "Welcome to StudyGe",
          "AuthMethods_sign_in_google": "Sign in with Google",
          "AuthMethods_sign_in_apple": "Sign in with Apple",
          "AuthMethods_sign_in_email": "Sign in with Email",
          "AuthMethods_or": "OR",
          "AuthMethods_email_placeholder": "Email address",
          "AuthMethods_footer_text": "By continuing, you agree to the following terms:",
          "AuthMethods_terms_of_use": "Terms of Service",
          "AuthMethods_and": "and",
          "AuthMethods_privacy_policy": "Privacy Policy",
          "AuthMethods_footer_text_end": "— and confirm that you have read them.",
          "AuthMethods_error": {
            "title": "Error occurred",
            "invalid_email": "Invalid email entered",
            "invalid_code": "Invalid code. Please try again.",
            "auth_conflict": "This login method is already linked to an account",
            "auth_used": "Another user has already used this login method",
            "auth_not_found": "User not found",
            "auth_access": "Access error",
            "auth_failed": "Authorization failed. Please try again.",
            "auth_conflict": "This authentication method is already linked to your account or another user's account.",
            "auth_invalid_token": "The provided access token is invalid.",
            "auth_invalid_data": "The server received invalid data. Please try again.",
            "auth_server_error": "An unknown error occurred on the server. Please try again later.",
            "unknown": "Unknown error"
          },
          "VerificationCodeInput": {
            "title": "Verification code",
            "description": "A message with a verification code has been sent to your devices. Enter the code to continue.",
            "resend_text": "Didn't receive code?",
            "resend_button": "Request again",
            "resend_timer": "Resend available in ({{seconds}}s)",
            "back_button": "Back"
          },
          "Modal close button": "Close",
          "Access to premium subscription in the app": "Access to all Premium features, statistics, and ad removal in the app",
          "Family Subscription": "Family Subscription",
          "Invite Member": "Invite Member",
          "Enter member ID": "Enter member ID",
          "No family subscription found.": "No family subscription found.",
          "No family members added yet.": "No family members added yet.",
          "Remove": "Remove",
          "Leave Family Subscription": "Leave Family Subscription",
          "Your premium access is expired, contact to your subscriptions head": "Your premium access is expired, contact your subscription head",
          "Success": "Success",
          "You have successfully left the family subscription.": "You have successfully left the family subscription.",
          "Error": "Error",
          "Failed to fetch family subscription info.": "Failed to fetch family subscription info.",
          "Failed to add family member. Please try again.": "Failed to add family member. Please try again.",
          "Failed to remove family member. Please try again.": "Failed to remove family member. Please try again.",
          "Failed to leave the family subscription.": "Failed to leave the family subscription.",
          "Only users with premium access can be the head of a family subscription.": "Only users with premium access can be the head of a family subscription.",
          "You are already a member of another family subscription and cannot invite members.": "You are already a member of another family subscription and cannot invite members.",
          "Invitee not found.": "The user you are trying to invite was not found.",
          "Invitee is already part of another family subscription.": "The user is already part of another family subscription.",
          "No available slots left in your family subscription.": "No available slots left in your family subscription.",
          "No family subscription found.": "No family subscription found.",
          "User is not a member of your family subscription.": "User is not a member of your family subscription.",
          "You do not have a family subscription.": "You do not have a family subscription.",
          "User not found.": "User not found.",
          "You are not part of a family subscription.": "You are not part of a family subscription.",
          "Error adding family member.": "Error adding family member.",
          "Error fetching family info.": "Error fetching family info.",
          "Error leaving family subscription.": "Error leaving family subscription.",
          "Error removing family member.": "Error removing family member.",
          "You have left the family subscription.": "You have left the family subscription.",
          "User added to the family subscription.": "User added to the family subscription.",
          "User removed from the family subscription.": "User removed from the family subscription.",
          "Add Member": "Add Member",
          "Family head": "Subscription Head",

          "SubscriptionOptions purchase for": "Purchase for {{price}}",
          "Cancel anytime.": "Cancel anytime.",
          "One-time payment. Access forever.": "One-time payment. Access forever.",
          "Product name monthly": "Premium subscription monthly",
          "Product name yearly": "Premium subscription yearly",
          "Product name forever": "Premium forever",

          "Something went wrong": "Oops... Something went wrong...",
          "Reload page": "Reload page",

          "footer.privacy":"Privacy policy",
          "footer.terms":"Terms of use",
          "footer.contact-us":"Contact us",
          "Manage subscription title": "Manage Subscription",
          "Manage subscription next payment": "Next payment date:",
          "Manage subscription subscription status": "Subscription status:",
          "Manage subscription subscription status Active": "Active",
          "Manage subscription subscription status not Active": "Inactive",
          "Manage subscription next payment price": "Payment amount:",
          "Manage subscription cancel": "Cancel subscription",
          "Manage subscription cancel confirmation text": "Are you sure you want to cancel the subscription? Access to Premium features will be lost immediately after cancellation.",
          "Manage subscription cancel confirmation title": "Confirm subscription cancellation",
          "Manage subscription cancel result success": "Subscription canceled",
          "Manage subscription cancel result fail": "Error canceling subscription: {{error}}"
        }
      },

      ru: {
        translation: {
          "googlePlayPurchasingIssues": {
            "title": "Проблемы с платежами в Google Play",
            "description1": "В связи с изменением политики Google Play мы вынуждены сменить юрисдикцию, чтобы продолжать использовать платежную систему Google Play. Так как Google уведомил об этом своих клиентов слишком поздно, то у нас не было времени, чтобы сделать процесс перехода бесшовным для наших пользователей, поэтому с <b>26 декабря 2024 года</b> платежи через систему оплаты Google Play не будут работать в течение некоторого времени. Также все существующие подписки, которые были оформлены ранее этой даты и оплаченный срок которых окончился, не смогут быть проделны, пока платежи через Google Play не возобновятся.",
            "description2": "В данный момент мы работаем над тем, чтобы <b>платежи через Google Play возобновились как можно скорее</b>, однако в данном вопросе многое зависит не от нас, поэтому этой займет какое-то время. Надеемся на ваше понимание.",
            "description3": "Если у вас остались какие-то вопросы или проблемы, пожалуйста, напишите нам на почту <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
          },
          "Account Data": "Аккаунт",
          "Providers": "Способы входа",
          "Logout": "Выйти из аккаунта",
          "Enter promo title": "Промокоды",
          "Enter promo placeholder": "Введите промокод",
          "Enter promo button": "Ввести",
          "Promocode activated title": "Промокод активирован",
          "Promocode activated you received": "Вы получили:",
          "Promocode activated you received Premium until": "Premium подписку до {{date}}",
          "Promocode not activated title": "Ошибка активации промокода",
          "Promocode activation error unknown": "Неизвестная ошибка",
          "Promocode activation error not found": "Промокод не найден",
          "Promocode activation error already used": "Промокод уже был использован",
          "Promocode activation error you already used": "Вы уже использовали этот промокод",
          "Login methods title": "Способы входа",
          "Login methods no data": "Отсутствуют способы входа",
          "Login methods add method": "Добавить способ входа",
          "Active subscription title": "Активные подписки",
          "Active subscription active untill": "Следующее списание {{date}}",
          "Active subscription manage subscription": "Управление подпиской",
          "Active subscription no subscriptions": "Нет активных подписок",
          "Active subscription per month": "На месяц",
          "Active subscription per year": "На год",
          "Active subscription forever": "Навсегда",
          "Active subscription subscription": "Подписка",
          "Active subscription subscription title": "Подписка Premium ({{type}})",
          "Active subscription no ads": "Без рекламы",
          "Active subscription one-time purchase": "Разовая покупка",
          "Active subscription promocode": "Промокод",
          "SubscriptionOptions title": "Получить Premium",
          "SubscriptionOptions available for russia": "Подходит для пользователей из России. (Рекомендуем выключить VPN для корректной работы)",
          "SubscriptionOptions price monthly": "{{price}} в месяц",
          "SubscriptionOptions price yearly": "{{price}} в год",
          "SubscriptionOptions price forever": "{{price}} навсегда",
          "SubscriptionOptions monthly subscription": "Месячная подписка",
          "SubscriptionOptions yearly subscription": "Годовая подписка",
          "SubscriptionOptions forever subscription": "Разовая покупка",
          "SubscriptionOptions continue button": "Продолжить",
          "AuthMethods_welcome_title": "Добро пожаловать в StudyGe",
          "AuthMethods_sign_in_google": "Войти через Google",
          "AuthMethods_sign_in_apple": "Войти через Apple",
          "AuthMethods_sign_in_email": "Войти через Email",
          "AuthMethods_or": "ИЛИ",
          "AuthMethods_email_placeholder": "Адрес электронной почты",
          "AuthMethods_footer_text": "Продолжая, вы соглашаетесь со следующими положениями:",
          "AuthMethods_terms_of_use": "Условия предоставления услуг",
          "AuthMethods_and": "и",
          "AuthMethods_privacy_policy": "Политика конфиденциальности",
          "AuthMethods_footer_text_end": "— и подтверждаете, что прочли их.",
          "AuthMethods_error": {
            "title": "Произошла ошибка",
            "invalid_email": "Введен некорректный email",
            "invalid_code": "Неверный код. Попробуйте снова.",
            "auth_conflict": "К аккаунту уже привязан этот способ входа",
            "auth_used": "Другой пользователь уже использовал этот способ входа",
            "auth_not_found": "Пользователь не найден",
            "auth_access": "Ошибка доступа",
            "auth_failed": "Ошибка авторизации. Проверьте правильность введенных данных.",
            "auth_conflict": "Этот способ авторизации уже привязан к вашему аккаунту или аккаунту другого пользователя.",
            "auth_invalid_token": "Предоставленный токен доступа недействителен.",
            "auth_invalid_data": "Сервер получил некорректные данные. Попробуйте снова.",
            "auth_server_error": "На сервере произошла неизвестная ошибка. Попробуйте позже.",
            "unknown": "Неизвестная ошибка"
          },
          "VerificationCodeInput": {
            "title": "Код подтверждения",
            "description": "Сообщение с кодом подтверждения было отправлено на ваши устройства. Введите код для продолжения.",
            "resend_text": "Не получили код?",
            "resend_button": "Запросить снова",
            "resend_timer": "Повторная отправка доступна через ({{seconds}}с)",
            "back_button": "Назад"
          },
          "Modal close button": "Закрыть",
          "Access to premium subscription in the app": "Доступ ко всем Premium дополнениям, статистике и удаление рекламы в приложении",

          "Family Subscription": "Семейная подписка",
          "Invite Member": "Пригласить участника",
          "Enter member ID": "Введите ID участника",
          "No family subscription found.": "Семейная подписка не найдена.",
          "No family members added yet.": "Участники семейной подписки не добавлены.",
          "Remove": "Удалить",
          "Leave Family Subscription": "Выйти из семейной подписки",
          "Your premium access is expired, contact to your subscriptions head": "Ваш доступ к премиум подписке истек, свяжитесь с администратором подписки",
          "Success": "Успех",
          "You have successfully left the family subscription.": "Вы успешно вышли из семейной подписки.",
          "Error": "Ошибка",
          "Failed to fetch family subscription info.": "Не удалось получить информацию о семейной подписке.",
          "Failed to add family member. Please try again.": "Не удалось добавить участника семейной подписки. Пожалуйста, попробуйте снова.",
          "Failed to remove family member. Please try again.": "Не удалось удалить участника семейной подписки. Пожалуйста, попробуйте снова.",
          "Failed to leave the family subscription.": "Не удалось выйти из семейной подписки.",
          "Add Member": "Добавить участника",
          "Family head": "Глава подписки",
          "Only users with premium access can be the head of a family subscription.": "Только пользователи с премиум-доступом могут быть главой семейной подписки.",
          "You are already a member of another family subscription and cannot invite members.": "Вы уже являетесь участником другой семейной подписки и не можете приглашать новых участников.",
          "Invitee not found.": "Пользователь, которого вы пытаетесь пригласить, не найден.",
          "Invitee is already part of another family subscription.": "Пользователь уже участвует в другой семейной подписке.",
          "No available slots left in your family subscription.": "В вашей семейной подписке больше нет доступных мест.",
          "No family subscription found.": "Семейная подписка не найдена.",
          "User is not a member of your family subscription.": "Пользователь не является участником вашей семейной подписки.",
          "You do not have a family subscription.": "У вас нет семейной подписки.",
          "User not found.": "Пользователь не найден.",
          "You are not part of a family subscription.": "Вы не являетесь участником семейной подписки.",
          "Error adding family member.": "Ошибка при добавлении участника в семейную подписку.",
          "Error fetching family info.": "Ошибка при получении информации о семейной подписке.",
          "Error leaving family subscription.": "Ошибка при выходе из семейной подписки.",
          "Error removing family member.": "Ошибка при удалении участника из семейной подписки.",
          "You have left the family subscription.": "Вы вышли из семейной подписки.",
          "User added to the family subscription.": "Пользователь добавлен в семейную подписку.",
          "User removed from the family subscription.": "Пользователь удален из семейной подписки.",

          "SubscriptionOptions purchase for": "Купить за {{price}}",
          "Cancel anytime.": "Отмена в любое время.",
          "One-time payment. Access forever.": "Разовая покупка. Premium доступ навсегда.",
          "Product name monthly": "Premium подписка на месяц",
          "Product name yearly": "Premium подписка на год",
          "Product name forever": "Premium навсегда",

          "Something went wrong": "Упс... Кажется что-то пошло не так...",
          "Reload page": "Перезагрузить страницу",

          "footer.privacy":"Политика конфиденциальности",
          "footer.terms":"Условия использования",
          "footer.contact-us":"Связаться с нами",

          "Manage subscription title":"Управление подпиской",
          "Manage subscription next payment":"Дата следующего платежа:",
          "Manage subscription subscription status":"Статус подписки:",
          "Manage subscription subscription status Active":"Активна",
          "Manage subscription subscription status not Active":"Неактивна",
          "Manage subscription next payment price":"Сумма списания:",
          "Manage subscription cancel":"Отменить подписку",
          "Manage subscription cancel confirmation text":"Вы уверены, что хотите отменить подписку? Доступ к Premium функциям будет потерян сразу после отмены подписки.",
          "Manage subscription cancel confirmation title":"Подтвердите отмену подписки",
          "Manage subscription cancel result success":"Подписка отменена",
          "Manage subscription cancel result fail":"Ошибка при отмене подписки: {{error}}",
        }
      },

      "ar": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "مشكلات الدفع على Google Play",
            "description1": "نظرًا لتغييرات سياسة Google Play، نحن مضطرون إلى تغيير الولاية القضائية للاستمرار في استخدام نظام الدفع في Google Play. ونظرًا لأن Google لم توفر وقتًا كافيًا لذلك، لم يكن لدينا وقت كافٍ لجعل عملية الانتقال سلسة لمستخدمينا. لذلك، بدءًا من <b>26 ديسمبر 2024</b>، لن تعمل المدفوعات عبر نظام الدفع في Google Play لفترة من الوقت. كما أن جميع الاشتراكات الحالية التي تم إنشاؤها قبل هذا التاريخ وانتهت فترة الدفع الخاصة بها، لا يمكن تجديدها حتى يتم استئناف المدفوعات عبر Google Play.",
            "description2": "نعمل حاليًا على <b>استئناف المدفوعات عبر Google Play في أقرب وقت ممكن</b>. ومع ذلك، هناك الكثير من الأمور الخارجة عن سيطرتنا في هذا الأمر، لذا سيستغرق الأمر بعض الوقت. نأمل في تفهمكم.",
            "description3": "إذا كانت لديك أي أسئلة أو مشكلات، يرجى مراسلتنا عبر البريد الإلكتروني على <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "البيانات الحسابية",
          "Providers": "طرق الدخول",
          "Logout": "تسجيل الخروج",
          "Enter promo title": "الرموز الترويجية",
          "Enter promo placeholder": "أدخل الرمز الترويجي",
          "Enter promo button": "أدخل",
          "Promocode activated title": "تم تفعيل الرمز الترويجي",
          "Promocode activated you received": "لقد حصلت على:",
          "Promocode activated you received Premium until": "اشتراك Premium حتى {{date}}",
          "Promocode not activated title": "خطأ في تفعيل الرمز الترويجي",
          "Promocode activation error unknown": "خطأ غير معروف",
          "Promocode activation error not found": "لم يتم العثور على الرمز الترويجي",
          "Promocode activation error already used": "تم استخدام الرمز الترويجي من قبل",
          "Promocode activation error you already used": "لقد استخدمت هذا الرمز الترويجي بالفعل",
          "Login methods title": "طرق الدخول",
          "Login methods no data": "لا توجد طرق دخول",
          "Login methods add method": "أضف طريقة دخول",
          "Active subscription title": "الاشتراكات النشطة",
          "Active subscription active untill": "الدفع التالي {{date}}",
          "Active subscription manage subscription": "إدارة الاشتراك",
          "Active subscription no subscriptions": "لا توجد اشتراكات نشطة",
          "Active subscription per month": "شهريًا",
          "Active subscription per year": "سنويا",
          "Active subscription forever": "مدى الحياة",
          "Active subscription subscription": "الاشتراك",
          "Active subscription subscription title": "اشتراك Premium ({{type}})",
          "Active subscription no ads": "بدون إعلانات",
          "Active subscription one-time purchase": "عملية شراء لمرة واحدة",
          "Active subscription promocode": "رمز ترويجي",
          "SubscriptionOptions title": "احصل على Premium",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} شهريًا",
          "SubscriptionOptions price yearly": "{{price}} سنويًا",
          "SubscriptionOptions price forever": "{{price}} مدى الحياة",
          "SubscriptionOptions monthly subscription": "اشتراك شهري",
          "SubscriptionOptions yearly subscription": "اشتراك سنوي",
          "SubscriptionOptions forever subscription": "شراء لمرة واحدة",
          "SubscriptionOptions continue button": "متابعة",
          "AuthMethods_welcome_title": "مرحبًا بك في StudyGe",
          "AuthMethods_sign_in_google": "الدخول عبر Google",
          "AuthMethods_sign_in_apple": "الدخول عبر Apple",
          "AuthMethods_sign_in_email": "الدخول عبر البريد الإلكتروني",
          "AuthMethods_or": "أو",
          "AuthMethods_email_placeholder": "عنوان البريد الإلكتروني",
          "AuthMethods_footer_text": "بالمتابعة، فإنك توافق على الشروط التالية:",
          "AuthMethods_terms_of_use": "شروط الخدمة",
          "AuthMethods_and": "و",
          "AuthMethods_privacy_policy": "سياسة الخصوصية",
          "AuthMethods_footer_text_end": "— وتؤكد أنك قرأتها.",
          "AuthMethods_error": {
            "title": "حدث خطأ",
            "invalid_email": "البريد الإلكتروني المدخل غير صحيح",
            "invalid_code": "الرمز غير صحيح. حاول مرة أخرى.",
            "auth_conflict": "تم ربط طريقة الدخول هذه بالحساب بالفعل",
            "auth_used": "تم استخدام طريقة الدخول هذه من قبل مستخدم آخر",
            "auth_not_found": "لم يتم العثور على المستخدم",
            "auth_access": "خطأ في الوصول",
            "auth_failed": "خطأ في التحقق. تحقق من صحة البيانات المدخلة.",
            "auth_conflict": "طريقة التحقق هذه مرتبطة بحسابك أو بحساب مستخدم آخر.",
            "auth_invalid_token": "الرمز المقدم غير صالح.",
            "auth_invalid_data": "تلقى الخادم بيانات غير صحيحة. حاول مرة أخرى.",
            "auth_server_error": "حدث خطأ غير معروف على الخادم. حاول مرة أخرى لاحقًا.",
            "unknown": "خطأ غير معروف"
          },
          "VerificationCodeInput": {
            "title": "رمز التحقق",
            "description": "تم إرسال رسالة تحتوي على رمز التحقق إلى أجهزتك. أدخل الرمز للمتابعة.",
            "resend_text": "لم تتلق الرمز؟",
            "resend_button": "أعد الطلب",
            "resend_timer": "إعادة الإرسال متاحة بعد ({{seconds}} ثانية)",
            "back_button": "العودة"
          },
          "Modal close button": "إغلاق",
          "Access to premium subscription in the app": "الوصول إلى جميع الميزات المميزة، الإحصاءات، وإزالة الإعلانات في التطبيق",
          "Family Subscription": "اشتراك العائلة",
          "Invite Member": "دعوة عضو",
          "Enter member ID": "أدخل معرف العضو",
          "No family subscription found.": "لم يتم العثور على اشتراك عائلي.",
          "No family members added yet.": "لم تتم إضافة أعضاء للعائلة بعد.",
          "Remove": "إزالة",
          "Leave Family Subscription": "مغادرة اشتراك العائلة",
          "Your premium access is expired, contact to your subscriptions head": "انتهت صلاحية وصولك المميز، يرجى التواصل مع مدير الاشتراك",
          "Success": "نجاح",
          "You have successfully left the family subscription.": "لقد غادرت اشتراك العائلة بنجاح.",
          "Error": "خطأ",
          "Failed to fetch family subscription info.": "فشل في جلب معلومات اشتراك العائلة.",
          "Failed to add family member. Please try again.": "فشل في إضافة عضو للعائلة. يرجى المحاولة مجددًا.",
          "Failed to remove family member. Please try again.": "فشل في إزالة عضو من العائلة. يرجى المحاولة مجددًا.",
          "Failed to leave the family subscription.": "فشل في مغادرة اشتراك العائلة.",
          "Only users with premium access can be the head of a family subscription.": "فقط المستخدمون الذين لديهم وصول مميز يمكنهم أن يكونوا رؤساء الاشتراك العائلي.",
          "You are already a member of another family subscription and cannot invite members.": "أنت بالفعل عضو في اشتراك عائلي آخر ولا يمكنك دعوة الأعضاء.",
          "Invitee not found.": "لم يتم العثور على المستخدم الذي تحاول دعوته.",
          "Invitee is already part of another family subscription.": "المستخدم بالفعل جزء من اشتراك عائلي آخر.",
          "No available slots left in your family subscription.": "لا توجد فتحات متاحة في اشتراكك العائلي.",
          "No family subscription found.": "لم يتم العثور على اشتراك عائلي.",
          "User is not a member of your family subscription.": "المستخدم ليس عضوًا في اشتراكك العائلي.",
          "You do not have a family subscription.": "ليس لديك اشتراك عائلي.",
          "User not found.": "لم يتم العثور على المستخدم.",
          "You are not part of a family subscription.": "أنت لست جزءًا من اشتراك عائلي.",
          "Error adding family member.": "خطأ في إضافة عضو العائلة.",
          "Error fetching family info.": "خطأ في جلب معلومات الاشتراك العائلي.",
          "Error leaving family subscription.": "خطأ في مغادرة الاشتراك العائلي.",
          "Error removing family member.": "خطأ في إزالة عضو العائلة.",
          "You have left the family subscription.": "لقد غادرت الاشتراك العائلي.",
          "User added to the family subscription.": "تمت إضافة المستخدم إلى الاشتراك العائلي.",
          "User removed from the family subscription.": "تمت إزالة المستخدم من الاشتراك العائلي.",
          "Add Member": "إضافة عضو",
          "Family head": "رئيس الاشتراك",

          "SubscriptionOptions purchase for": "شراء مقابل {{price}}",
          "Cancel anytime.": "إلغاء في أي وقت.",
          "One-time payment. Access forever.": "دفعة واحدة. الوصول إلى الأبد.",
          "Product name monthly": "اشتراك بريميوم شهري",
          "Product name yearly": "اشتراك بريميوم سنوي",
          "Product name forever": "بريميوم للأبد",

          "Something went wrong": "عذرًا... حدث خطأ ما...",
          "Reload page": "إعادة تحميل الصفحة",

          "footer.privacy": "سياسة الخصوصية",
          "footer.terms": "شروط الاستخدام",
          "footer.contact-us": "اتصل بنا",

          "Manage subscription title": "إدارة الاشتراك",
          "Manage subscription next payment": "تاريخ الدفع التالي:",
          "Manage subscription subscription status": "حالة الاشتراك:",
          "Manage subscription subscription status Active": "نشط",
          "Manage subscription subscription status not Active": "غير نشط",
          "Manage subscription next payment price": "مبلغ الدفع:",
          "Manage subscription cancel": "إلغاء الاشتراك",
          "Manage subscription cancel confirmation text": "هل أنت متأكد أنك تريد إلغاء الاشتراك؟ سيتم فقدان الوصول إلى الميزات المميزة فورًا بعد الإلغاء.",
          "Manage subscription cancel confirmation title": "تأكيد إلغاء الاشتراك",
          "Manage subscription cancel result success": "تم إلغاء الاشتراك",
          "Manage subscription cancel result fail": "خطأ في إلغاء الاشتراك: {{error}}"

        }
      },
      "ch": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Google Play 支付问题",
            "description1": "由于 Google Play 政策的更改，我们被迫更改管辖权以继续使用 Google Play 支付系统。由于 Google 提供的时间不足，我们没有足够的时间为用户实现无缝过渡。因此，从 <b>2024年12月26日</b>起，Google Play 支付系统的支付将暂时无法使用。此外，所有在此日期之前创建且已付费期限到期的现有订阅，在 Google Play 支付恢复之前无法续订。",
            "description2": "我们目前正在努力<b>尽快恢复 Google Play 支付</b>。然而，在这个问题上，有很多事情我们无法控制，因此需要一些时间。感谢您的理解。",
            "description3": "如果您有任何问题或疑虑，请通过电子邮件联系我们 <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "账户数据",
          "Providers": "登录方式",
          "Logout": "退出登录",
          "Enter promo title": "促销码",
          "Enter promo placeholder": "输入促销码",
          "Enter promo button": "输入",
          "Promocode activated title": "促销码已激活",
          "Promocode activated you received": "您收到：",
          "Promocode activated you received Premium until": "Premium 订阅至 {{date}}",
          "Promocode not activated title": "促销码激活失败",
          "Promocode activation error unknown": "未知错误",
          "Promocode activation error not found": "找不到促销码",
          "Promocode activation error already used": "促销码已被使用",
          "Promocode activation error you already used": "您已使用此促销码",
          "Login methods title": "登录方式",
          "Login methods no data": "没有可用的登录方式",
          "Login methods add method": "添加登录方式",
          "Active subscription title": "活跃订阅",
          "Active subscription active untill": "下次付款 {{date}}",
          "Active subscription manage subscription": "管理订阅",
          "Active subscription no subscriptions": "没有活跃订阅",
          "Active subscription per month": "每月",
          "Active subscription per year": "每年",
          "Active subscription forever": "永久",
          "Active subscription subscription": "订阅",
          "Active subscription subscription title": "Premium 订阅 ({{type}})",
          "Active subscription no ads": "无广告",
          "Active subscription one-time purchase": "一次性购买",
          "Active subscription promocode": "促销码",
          "SubscriptionOptions title": "获取 Premium",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} 每月",
          "SubscriptionOptions price yearly": "{{price}} 每年",
          "SubscriptionOptions price forever": "{{price}} 永久",
          "SubscriptionOptions monthly subscription": "月度订阅",
          "SubscriptionOptions yearly subscription": "年度订阅",
          "SubscriptionOptions forever subscription": "一次性购买",
          "SubscriptionOptions continue button": "继续",
          "AuthMethods_welcome_title": "欢迎使用 StudyGe",
          "AuthMethods_sign_in_google": "通过 Google 登录",
          "AuthMethods_sign_in_apple": "通过 Apple 登录",
          "AuthMethods_sign_in_email": "通过电子邮件登录",
          "AuthMethods_or": "或",
          "AuthMethods_email_placeholder": "电子邮件地址",
          "AuthMethods_footer_text": "继续即表示您同意以下条款：",
          "AuthMethods_terms_of_use": "服务条款",
          "AuthMethods_and": "和",
          "AuthMethods_privacy_policy": "隐私政策",
          "AuthMethods_footer_text_end": "— 并确认您已阅读。",
          "AuthMethods_error": {
            "title": "发生错误",
            "invalid_email": "输入的电子邮件无效",
            "invalid_code": "代码无效。请再试一次。",
            "auth_conflict": "该登录方式已绑定到帐户",
            "auth_used": "另一用户已使用该登录方式",
            "auth_not_found": "找不到用户",
            "auth_access": "访问错误",
            "auth_failed": "验证失败。请检查输入的详细信息是否正确。",
            "auth_conflict": "该登录方式已绑定到您的帐户或其他用户的帐户。",
            "auth_invalid_token": "提供的访问令牌无效。",
            "auth_invalid_data": "服务器接收到无效数据。请再试一次。",
            "auth_server_error": "服务器发生未知错误。请稍后再试。",
            "unknown": "未知错误"
          },
          "VerificationCodeInput": {
            "title": "验证码",
            "description": "带有验证码的消息已发送到您的设备。输入验证码以继续。",
            "resend_text": "没有收到验证码？",
            "resend_button": "重新发送",
            "resend_timer": "{{seconds}} 秒后可重新发送",
            "back_button": "返回"
          },
          "Modal close button": "关闭",
          "Access to premium subscription in the app": "应用中所有高级功能、统计信息和广告删除的访问权限",
          "Family Subscription": "家庭订阅",
          "Invite Member": "邀请成员",
          "Enter member ID": "输入成员ID",
          "No family subscription found.": "未找到家庭订阅。",
          "No family members added yet.": "尚未添加家庭成员。",
          "Remove": "移除",
          "Leave Family Subscription": "退出家庭订阅",
          "Your premium access is expired, contact to your subscriptions head": "您的高级访问权限已过期，请联系订阅负责人",
          "Success": "成功",
          "You have successfully left the family subscription.": "您已成功退出家庭订阅。",
          "Error": "错误",
          "Failed to fetch family subscription info.": "获取家庭订阅信息失败。",
          "Failed to add family member. Please try again.": "添加家庭成员失败。请再试一次。",
          "Failed to remove family member. Please try again.": "移除家庭成员失败。请再试一次。",
          "Failed to leave the family subscription.": "退出家庭订阅失败。",
          "Only users with premium access can be the head of a family subscription.": "只有拥有高级访问权限的用户才能成为家庭订阅的负责人。",
          "You are already a member of another family subscription and cannot invite members.": "您已经是其他家庭订阅的成员，无法邀请新成员。",
          "Invitee not found.": "未找到您尝试邀请的用户。",
          "Invitee is already part of another family subscription.": "该用户已是另一个家庭订阅的一部分。",
          "No available slots left in your family subscription.": "您的家庭订阅中没有可用位置。",
          "No family subscription found.": "未找到家庭订阅。",
          "User is not a member of your family subscription.": "该用户不是您的家庭订阅成员。",
          "You do not have a family subscription.": "您没有家庭订阅。",
          "User not found.": "未找到用户。",
          "You are not part of a family subscription.": "您不是家庭订阅的成员。",
          "Error adding family member.": "添加家庭成员时出错。",
          "Error fetching family info.": "获取家庭订阅信息时出错。",
          "Error leaving family subscription.": "退出家庭订阅时出错。",
          "Error removing family member.": "移除家庭成员时出错。",
          "You have left the family subscription.": "您已退出家庭订阅。",
          "User added to the family subscription.": "用户已加入家庭订阅。",
          "User removed from the family subscription.": "用户已被移除家庭订阅。",
          "Add Member": "添加成员",
          "Family head": "订阅负责人",

          "SubscriptionOptions purchase for": "购买价格 {{price}}",
          "Cancel anytime.": "随时取消。",
          "One-time payment. Access forever.": "一次性支付。 永久访问。",
          "Product name monthly": "高级订阅每月",
          "Product name yearly": "高级订阅每年",
          "Product name forever": "高级永久",
          "Something went wrong": "哎呀...好像出了点问题...",
          "Reload page": "重新加载页面",

          "footer.privacy": "隐私政策",
          "footer.terms": "使用条款",
          "footer.contact-us": "联系我们",

          "Manage subscription title": "管理订阅",
          "Manage subscription next payment": "下次付款日期:",
          "Manage subscription subscription status": "订阅状态:",
          "Manage subscription subscription status Active": "活跃",
          "Manage subscription subscription status not Active": "非活跃",
          "Manage subscription next payment price": "付款金额:",
          "Manage subscription cancel": "取消订阅",
          "Manage subscription cancel confirmation text": "您确定要取消订阅吗？取消订阅后，Premium 功能的访问权限将立即丧失。",
          "Manage subscription cancel confirmation title": "确认取消订阅",
          "Manage subscription cancel result success": "订阅已取消",
          "Manage subscription cancel result fail": "取消订阅时出错：{{error}}"
        }
      },
      "de": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Probleme mit Zahlungen auf Google Play",
            "description1": "Aufgrund von Änderungen in den Google Play-Richtlinien sind wir gezwungen, die Gerichtsbarkeit zu ändern, um weiterhin das Zahlungssystem von Google Play zu nutzen. Da Google dafür nicht genügend Zeit eingeräumt hat, hatten wir nicht genügend Zeit, um den Übergangsprozess für unsere Nutzer nahtlos zu gestalten. Ab dem <b>26. Dezember 2024</b> werden Zahlungen über das Google Play-Zahlungssystem daher für einige Zeit nicht funktionieren. Außerdem können alle bestehenden Abonnements, die vor diesem Datum erstellt wurden und deren bezahlter Zeitraum abgelaufen ist, nicht verlängert werden, bis die Zahlungen über Google Play wieder aufgenommen werden.",
            "description2": "Wir arbeiten derzeit daran, <b>die Zahlungen über Google Play so schnell wie möglich wieder aufzunehmen</b>. In dieser Angelegenheit liegt jedoch vieles nicht in unserer Kontrolle, daher wird es einige Zeit dauern. Wir bitten um Ihr Verständnis.",
            "description3": "Wenn Sie Fragen oder Probleme haben, kontaktieren Sie uns bitte per E-Mail unter <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Kontodaten",
          "Providers": "Anmeldemethoden",
          "Logout": "Abmelden",
          "Enter promo title": "Aktionscodes",
          "Enter promo placeholder": "Geben Sie den Aktionscode ein",
          "Enter promo button": "Eingeben",
          "Promocode activated title": "Aktionscode aktiviert",
          "Promocode activated you received": "Sie haben erhalten:",
          "Promocode activated you received Premium until": "Premium-Abonnement bis {{date}}",
          "Promocode not activated title": "Aktionscode-Aktivierungsfehler",
          "Promocode activation error unknown": "Unbekannter Fehler",
          "Promocode activation error not found": "Aktionscode nicht gefunden",
          "Promocode activation error already used": "Aktionscode wurde bereits verwendet",
          "Promocode activation error you already used": "Sie haben diesen Aktionscode bereits verwendet",
          "Login methods title": "Anmeldemethoden",
          "Login methods no data": "Keine Anmeldemethoden verfügbar",
          "Login methods add method": "Anmeldemethode hinzufügen",
          "Active subscription title": "Aktive Abonnements",
          "Active subscription active untill": "Nächste Zahlung {{date}}",
          "Active subscription manage subscription": "Abonnement verwalten",
          "Active subscription no subscriptions": "Keine aktiven Abonnements",
          "Active subscription per month": "Monatlich",
          "Active subscription per year": "Jährlich",
          "Active subscription forever": "Für immer",
          "Active subscription subscription": "Abonnement",
          "Active subscription subscription title": "Premium-Abonnement ({{type}})",
          "Active subscription no ads": "Keine Werbung",
          "Active subscription one-time purchase": "Einmalkauf",
          "Active subscription promocode": "Aktionscode",
          "SubscriptionOptions title": "Premium erhalten",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} pro Monat",
          "SubscriptionOptions price yearly": "{{price}} pro Jahr",
          "SubscriptionOptions price forever": "{{price}} für immer",
          "SubscriptionOptions monthly subscription": "Monatsabonnement",
          "SubscriptionOptions yearly subscription": "Jahresabonnement",
          "SubscriptionOptions forever subscription": "Einmalkauf",
          "SubscriptionOptions continue button": "Weiter",
          "AuthMethods_welcome_title": "Willkommen bei StudyGe",
          "AuthMethods_sign_in_google": "Mit Google anmelden",
          "AuthMethods_sign_in_apple": "Mit Apple anmelden",
          "AuthMethods_sign_in_email": "Mit E-Mail anmelden",
          "AuthMethods_or": "ODER",
          "AuthMethods_email_placeholder": "E-Mail-Adresse",
          "AuthMethods_footer_text": "Mit der Fortsetzung stimmen Sie den folgenden Bedingungen zu:",
          "AuthMethods_terms_of_use": "Nutzungsbedingungen",
          "AuthMethods_and": "und",
          "AuthMethods_privacy_policy": "Datenschutzrichtlinie",
          "AuthMethods_footer_text_end": "— und bestätigen, dass Sie sie gelesen haben.",
          "AuthMethods_error": {
            "title": "Ein Fehler ist aufgetreten",
            "invalid_email": "Ungültige E-Mail-Adresse",
            "invalid_code": "Ungültiger Code. Bitte versuchen Sie es erneut.",
            "auth_conflict": "Diese Anmeldemethode ist bereits mit dem Konto verknüpft",
            "auth_used": "Diese Anmeldemethode wurde bereits von einem anderen Benutzer verwendet",
            "auth_not_found": "Benutzer nicht gefunden",
            "auth_access": "Zugriffsfehler",
            "auth_failed": "Authentifizierungsfehler. Bitte überprüfen Sie die eingegebenen Daten.",
            "auth_conflict": "Diese Anmeldemethode ist bereits mit Ihrem Konto oder dem Konto eines anderen Benutzers verknüpft.",
            "auth_invalid_token": "Der bereitgestellte Zugriffstoken ist ungültig.",
            "auth_invalid_data": "Der Server hat ungültige Daten erhalten. Bitte versuchen Sie es erneut.",
            "auth_server_error": "Auf dem Server ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
            "unknown": "Unbekannter Fehler"
          },
          "VerificationCodeInput": {
            "title": "Bestätigungscode",
            "description": "Eine Nachricht mit dem Bestätigungscode wurde an Ihre Geräte gesendet. Geben Sie den Code ein, um fortzufahren.",
            "resend_text": "Code nicht erhalten?",
            "resend_button": "Erneut anfordern",
            "resend_timer": "Erneutes Senden in ({{seconds}}s) verfügbar",
            "back_button": "Zurück"
          },
          "Modal close button": "Schließen",
          "Access to premium subscription in the app": "Zugang zu allen Premium-Funktionen, Statistiken und Werbeentfernung in der App",
          "Family Subscription": "Familienabonnement",
          "Invite Member": "Mitglied einladen",
          "Enter member ID": "Mitglieds-ID eingeben",
          "No family subscription found.": "Kein Familienabonnement gefunden.",
          "No family members added yet.": "Noch keine Familienmitglieder hinzugefügt.",
          "Remove": "Entfernen",
          "Leave Family Subscription": "Familienabonnement verlassen",
          "Your premium access is expired, contact to your subscriptions head": "Ihr Premium-Zugang ist abgelaufen, bitte wenden Sie sich an den Abonnementleiter",
          "Success": "Erfolg",
          "You have successfully left the family subscription.": "Sie haben das Familienabonnement erfolgreich verlassen.",
          "Error": "Fehler",
          "Failed to fetch family subscription info.": "Abrufen der Familienabonnementinformationen fehlgeschlagen.",
          "Failed to add family member. Please try again.": "Das Hinzufügen eines Familienmitglieds ist fehlgeschlagen. Bitte versuchen Sie es erneut.",
          "Failed to remove family member. Please try again.": "Entfernen eines Familienmitglieds fehlgeschlagen. Bitte versuchen Sie es erneut.",
          "Failed to leave the family subscription.": "Das Verlassen des Familienabonnements ist fehlgeschlagen.",
          "Only users with premium access can be the head of a family subscription.": "Nur Benutzer mit Premium-Zugang können das Familienabonnement leiten.",
          "You are already a member of another family subscription and cannot invite members.": "Sie sind bereits Mitglied eines anderen Familienabonnements und können keine Mitglieder einladen.",
          "Invitee not found.": "Der Benutzer, den Sie einladen möchten, wurde nicht gefunden.",
          "Invitee is already part of another family subscription.": "Der Benutzer ist bereits Teil eines anderen Familienabonnements.",
          "No available slots left in your family subscription.": "Keine verfügbaren Plätze in Ihrem Familienabonnement.",
          "No family subscription found.": "Kein Familienabonnement gefunden.",
          "User is not a member of your family subscription.": "Der Benutzer ist kein Mitglied Ihres Familienabonnements.",
          "You do not have a family subscription.": "Sie haben kein Familienabonnement.",
          "User not found.": "Benutzer nicht gefunden.",
          "You are not part of a family subscription.": "Sie sind kein Teil eines Familienabonnements.",
          "Error adding family member.": "Fehler beim Hinzufügen eines Familienmitglieds.",
          "Error fetching family info.": "Fehler beim Abrufen der Familienabonnementinformationen.",
          "Error leaving family subscription.": "Fehler beim Verlassen des Familienabonnements.",
          "Error removing family member.": "Fehler beim Entfernen des Familienmitglieds.",
          "You have left the family subscription.": "Sie haben das Familienabonnement verlassen.",
          "User added to the family subscription.": "Benutzer zum Familienabonnement hinzugefügt.",
          "User removed from the family subscription.": "Benutzer aus dem Familienabonnement entfernt.",
          "Add Member": "Mitglied hinzufügen",
          "Family head": "Abonnementleiter",

          "SubscriptionOptions purchase for": "Kaufen für {{price}}",
          "Cancel anytime.": "Jederzeit kündbar.",
          "One-time payment. Access forever.": "Einmalige Zahlung. Zugang für immer.",
          "Product name monthly": "Premium-Abonnement monatlich",
          "Product name yearly": "Premium-Abonnement jährlich",
          "Product name forever": "Premium für immer",
          "Something went wrong": "Ups... Etwas ist schief gelaufen...",
          "Reload page": "Seite neu laden",

          "footer.privacy": "Datenschutzrichtlinie",
          "footer.terms": "Nutzungsbedingungen",
          "footer.contact-us": "Kontaktieren Sie uns",

          "Manage subscription title": "Abonnement verwalten",
          "Manage subscription next payment": "Nächstes Zahlungsdatum:",
          "Manage subscription subscription status": "Abonnementstatus:",
          "Manage subscription subscription status Active": "Aktiv",
          "Manage subscription subscription status not Active": "Inaktiv",
          "Manage subscription next payment price": "Zahlungsbetrag:",
          "Manage subscription cancel": "Abonnement kündigen",
          "Manage subscription cancel confirmation text": "Sind Sie sicher, dass Sie das Abonnement kündigen möchten? Der Zugriff auf Premium-Funktionen geht sofort nach der Kündigung verloren.",
          "Manage subscription cancel confirmation title": "Abonnementkündigung bestätigen",
          "Manage subscription cancel result success": "Abonnement gekündigt",
          "Manage subscription cancel result fail": "Fehler beim Kündigen des Abonnements: {{error}}"
        

        }
      },
      "es": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Problemas con los pagos en Google Play",
            "description1": "Debido a cambios en las políticas de Google Play, nos vemos obligados a cambiar de jurisdicción para continuar utilizando el sistema de pago de Google Play. Dado que Google no proporcionó tiempo suficiente para esto, no tuvimos tiempo para hacer que el proceso de transición fuera fluido para nuestros usuarios. Por lo tanto, a partir del <b>26 de diciembre de 2024</b>, los pagos a través del sistema de pago de Google Play no funcionarán por un tiempo. Además, todas las suscripciones existentes creadas antes de esta fecha y cuyo período de pago haya finalizado no podrán renovarse hasta que se reanuden los pagos de Google Play.",
            "description2": "Actualmente estamos trabajando para <b>reanudar los pagos de Google Play lo antes posible</b>. Sin embargo, gran parte de este problema está fuera de nuestro control, por lo que tomará algo de tiempo. Agradecemos su comprensión.",
            "description3": "Si tiene alguna pregunta o problema, no dude en escribirnos al correo electrónico <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Datos de la cuenta",
          "Providers": "Métodos de inicio de sesión",
          "Logout": "Cerrar sesión",
          "Enter promo title": "Códigos promocionales",
          "Enter promo placeholder": "Ingrese el código promocional",
          "Enter promo button": "Ingresar",
          "Promocode activated title": "Código promocional activado",
          "Promocode activated you received": "Has recibido:",
          "Promocode activated you received Premium until": "Suscripción Premium hasta {{date}}",
          "Promocode not activated title": "Error en la activación del código promocional",
          "Promocode activation error unknown": "Error desconocido",
          "Promocode activation error not found": "Código promocional no encontrado",
          "Promocode activation error already used": "El código promocional ya ha sido utilizado",
          "Promocode activation error you already used": "Ya has utilizado este código promocional",
          "Login methods title": "Métodos de inicio de sesión",
          "Login methods no data": "No hay métodos de inicio de sesión disponibles",
          "Login methods add method": "Agregar método de inicio de sesión",
          "Active subscription title": "Suscripciones activas",
          "Active subscription active untill": "Próximo pago {{date}}",
          "Active subscription manage subscription": "Gestionar suscripción",
          "Active subscription no subscriptions": "No hay suscripciones activas",
          "Active subscription per month": "Por mes",
          "Active subscription per year": "Por año",
          "Active subscription forever": "Para siempre",
          "Active subscription subscription": "Suscripción",
          "Active subscription subscription title": "Suscripción Premium ({{type}})",
          "Active subscription no ads": "Sin anuncios",
          "Active subscription one-time purchase": "Compra única",
          "Active subscription promocode": "Código promocional",
          "SubscriptionOptions title": "Obtener Premium",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} al mes",
          "SubscriptionOptions price yearly": "{{price}} al año",
          "SubscriptionOptions price forever": "{{price}} para siempre",
          "SubscriptionOptions monthly subscription": "Suscripción mensual",
          "SubscriptionOptions yearly subscription": "Suscripción anual",
          "SubscriptionOptions forever subscription": "Compra única",
          "SubscriptionOptions continue button": "Continuar",
          "AuthMethods_welcome_title": "Bienvenido a StudyGe",
          "AuthMethods_sign_in_google": "Iniciar sesión con Google",
          "AuthMethods_sign_in_apple": "Iniciar sesión con Apple",
          "AuthMethods_sign_in_email": "Iniciar sesión con correo electrónico",
          "AuthMethods_or": "O",
          "AuthMethods_email_placeholder": "Dirección de correo electrónico",
          "AuthMethods_footer_text": "Al continuar, aceptas los siguientes términos:",
          "AuthMethods_terms_of_use": "Términos de servicio",
          "AuthMethods_and": "y",
          "AuthMethods_privacy_policy": "Política de privacidad",
          "AuthMethods_footer_text_end": "— y confirmas que los has leído.",
          "AuthMethods_error": {
            "title": "Ha ocurrido un error",
            "invalid_email": "Correo electrónico no válido",
            "invalid_code": "Código no válido. Inténtalo de nuevo.",
            "auth_conflict": "Este método de inicio de sesión ya está vinculado a la cuenta",
            "auth_used": "Otro usuario ya ha utilizado este método de inicio de sesión",
            "auth_not_found": "Usuario no encontrado",
            "auth_access": "Error de acceso",
            "auth_failed": "Error de autenticación. Verifica los datos ingresados.",
            "auth_conflict": "Este método de autenticación ya está vinculado a tu cuenta o a la cuenta de otro usuario.",
            "auth_invalid_token": "El token de acceso proporcionado no es válido.",
            "auth_invalid_data": "El servidor recibió datos no válidos. Inténtalo de nuevo.",
            "auth_server_error": "Ha ocurrido un error desconocido en el servidor. Inténtalo más tarde.",
            "unknown": "Error desconocido"
          },
          "VerificationCodeInput": {
            "title": "Código de verificación",
            "description": "Se envió un mensaje con el código de verificación a tus dispositivos. Ingresa el código para continuar.",
            "resend_text": "¿No recibiste el código?",
            "resend_button": "Reenviar",
            "resend_timer": "Reenvío disponible en ({{seconds}}s)",
            "back_button": "Atrás"
          },
          "Modal close button": "Cerrar",
          "Access to premium subscription in the app": "Acceso a todas las funciones Premium, estadísticas y eliminación de anuncios en la aplicación",
          "Family Subscription": "Suscripción familiar",
          "Invite Member": "Invitar miembro",
          "Enter member ID": "Ingrese ID de miembro",
          "No family subscription found.": "No se encontró suscripción familiar.",
          "No family members added yet.": "No se han agregado miembros de la familia.",
          "Remove": "Eliminar",
          "Leave Family Subscription": "Salir de la suscripción familiar",
          "Your premium access is expired, contact to your subscriptions head": "Su acceso premium ha expirado, comuníquese con el administrador de suscripciones",
          "Success": "Éxito",
          "You have successfully left the family subscription.": "Ha salido con éxito de la suscripción familiar.",
          "Error": "Error",
          "Failed to fetch family subscription info.": "Error al obtener la información de la suscripción familiar.",
          "Failed to add family member. Please try again.": "Error al agregar un miembro de la familia. Inténtalo de nuevo.",
          "Failed to remove family member. Please try again.": "Error al eliminar un miembro de la familia. Inténtalo de nuevo.",
          "Failed to leave the family subscription.": "Error al salir de la suscripción familiar.",
          "Only users with premium access can be the head of a family subscription.": "Solo los usuarios con acceso premium pueden ser el jefe de una suscripción familiar.",
          "You are already a member of another family subscription and cannot invite members.": "Ya eres miembro de otra suscripción familiar y no puedes invitar a nuevos miembros.",
          "Invitee not found.": "El usuario que intentas invitar no fue encontrado.",
          "Invitee is already part of another family subscription.": "El usuario ya es parte de otra suscripción familiar.",
          "No available slots left in your family subscription.": "No hay espacios disponibles en tu suscripción familiar.",
          "No family subscription found.": "No se encontró suscripción familiar.",
          "User is not a member of your family subscription.": "El usuario no es miembro de tu suscripción familiar.",
          "You do not have a family subscription.": "No tienes una suscripción familiar.",
          "User not found.": "Usuario no encontrado.",
          "You are not part of a family subscription.": "No eres parte de una suscripción familiar.",
          "Error adding family member.": "Error al agregar al miembro de la familia.",
          "Error fetching family info.": "Error al obtener la información de la suscripción familiar.",
          "Error leaving family subscription.": "Error al salir de la suscripción familiar.",
          "Error removing family member.": "Error al eliminar al miembro de la familia.",
          "You have left the family subscription.": "Has salido de la suscripción familiar.",
          "User added to the family subscription.": "Usuario añadido a la suscripción familiar.",
          "User removed from the family subscription.": "Usuario eliminado de la suscripción familiar.",
          "Add Member": "Agregar miembro",
          "Family head": "Cabeza de suscripción",

          "SubscriptionOptions purchase for": "Comprar por {{price}}",
          "Cancel anytime.": "Cancela en cualquier momento.",
          "One-time payment. Access forever.": "Pago único. Acceso para siempre.",
          "Product name monthly": "Suscripción premium mensual",
          "Product name yearly": "Suscripción premium anual",
          "Product name forever": "Premium para siempre",
          "Something went wrong": "Vaya... Algo salió mal...",
          "Reload page": "Recargar página",

          "footer.privacy": "Política de privacidad",
          "footer.terms": "Términos de uso",
          "footer.contact-us": "Contáctenos",

          "Manage subscription title": "Gestionar suscripción",
          "Manage subscription next payment": "Fecha del próximo pago:",
          "Manage subscription subscription status": "Estado de la suscripción:",
          "Manage subscription subscription status Active": "Activo",
          "Manage subscription subscription status not Active": "Inactivo",
          "Manage subscription next payment price": "Monto del pago:",
          "Manage subscription cancel": "Cancelar suscripción",
          "Manage subscription cancel confirmation text": "¿Está seguro de que desea cancelar la suscripción? El acceso a las funciones Premium se perderá inmediatamente después de la cancelación.",
          "Manage subscription cancel confirmation title": "Confirmar cancelación de suscripción",
          "Manage subscription cancel result success": "Suscripción cancelada",
          "Manage subscription cancel result fail": "Error al cancelar la suscripción: {{error}}"
        

        }
      },
      "fr": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Problèmes de paiement sur Google Play",
            "description1": "En raison des changements de politique de Google Play, nous sommes contraints de changer de juridiction pour continuer à utiliser le système de paiement Google Play. Comme Google n'a pas laissé suffisamment de temps pour cela, nous n'avons pas eu le temps nécessaire pour rendre la transition fluide pour nos utilisateurs. Par conséquent, à partir du <b>26 décembre 2024</b>, les paiements via le système de paiement Google Play ne fonctionneront plus pendant un certain temps. De plus, tous les abonnements existants créés avant cette date et dont la période payée a expiré ne pourront pas être renouvelés tant que les paiements via Google Play ne reprendront pas.",
            "description2": "Nous travaillons actuellement pour <b>reprendre les paiements sur Google Play dès que possible</b>. Cependant, une grande partie de ce problème est hors de notre contrôle, ce qui prendra donc un certain temps. Nous apprécions votre compréhension.",
            "description3": "Si vous avez des questions ou des problèmes, veuillez nous contacter par e-mail à <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Données du compte",
          "Providers": "Méthodes de connexion",
          "Logout": "Se déconnecter",
          "Enter promo title": "Codes promotionnels",
          "Enter promo placeholder": "Entrez le code promotionnel",
          "Enter promo button": "Entrer",
          "Promocode activated title": "Code promotionnel activé",
          "Promocode activated you received": "Vous avez reçu :",
          "Promocode activated you received Premium until": "Abonnement Premium jusqu'à {{date}}",
          "Promocode not activated title": "Erreur d'activation du code promotionnel",
          "Promocode activation error unknown": "Erreur inconnue",
          "Promocode activation error not found": "Code promotionnel introuvable",
          "Promocode activation error already used": "Le code promotionnel a déjà été utilisé",
          "Promocode activation error you already used": "Vous avez déjà utilisé ce code promotionnel",
          "Login methods title": "Méthodes de connexion",
          "Login methods no data": "Aucune méthode de connexion disponible",
          "Login methods add method": "Ajouter une méthode de connexion",
          "Active subscription title": "Abonnements actifs",
          "Active subscription active untill": "Prochain paiement {{date}}",
          "Active subscription manage subscription": "Gérer l'abonnement",
          "Active subscription no subscriptions": "Aucun abonnement actif",
          "Active subscription per month": "Par mois",
          "Active subscription per year": "Par an",
          "Active subscription forever": "Pour toujours",
          "Active subscription subscription": "Abonnement",
          "Active subscription subscription title": "Abonnement Premium ({{type}})",
          "Active subscription no ads": "Sans publicité",
          "Active subscription one-time purchase": "Achat unique",
          "Active subscription promocode": "Code promotionnel",
          "SubscriptionOptions title": "Obtenez Premium",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} par mois",
          "SubscriptionOptions price yearly": "{{price}} par an",
          "SubscriptionOptions price forever": "{{price}} pour toujours",
          "SubscriptionOptions monthly subscription": "Abonnement mensuel",
          "SubscriptionOptions yearly subscription": "Abonnement annuel",
          "SubscriptionOptions forever subscription": "Achat unique",
          "SubscriptionOptions continue button": "Continuer",
          "AuthMethods_welcome_title": "Bienvenue chez StudyGe",
          "AuthMethods_sign_in_google": "Se connecter avec Google",
          "AuthMethods_sign_in_apple": "Se connecter avec Apple",
          "AuthMethods_sign_in_email": "Se connecter avec l'email",
          "AuthMethods_or": "OU",
          "AuthMethods_email_placeholder": "Adresse e-mail",
          "AuthMethods_footer_text": "En continuant, vous acceptez les conditions suivantes :",
          "AuthMethods_terms_of_use": "Conditions de service",
          "AuthMethods_and": "et",
          "AuthMethods_privacy_policy": "Politique de confidentialité",
          "AuthMethods_footer_text_end": "— et confirmez que vous les avez lues.",
          "AuthMethods_error": {
            "title": "Une erreur est survenue",
            "invalid_email": "Email invalide",
            "invalid_code": "Code invalide. Réessayez.",
            "auth_conflict": "Cette méthode de connexion est déjà liée au compte",
            "auth_used": "Un autre utilisateur a déjà utilisé cette méthode de connexion",
            "auth_not_found": "Utilisateur non trouvé",
            "auth_access": "Erreur d'accès",
            "auth_failed": "Erreur d'authentification. Vérifiez vos informations.",
            "auth_conflict": "Cette méthode de connexion est déjà liée à votre compte ou à celui d'un autre utilisateur.",
            "auth_invalid_token": "Le jeton d'accès fourni est invalide.",
            "auth_invalid_data": "Le serveur a reçu des données invalides. Réessayez.",
            "auth_server_error": "Une erreur inconnue est survenue sur le serveur. Réessayez plus tard.",
            "unknown": "Erreur inconnue"
          },
          "VerificationCodeInput": {
            "title": "Code de vérification",
            "description": "Un message avec un code de vérification a été envoyé à vos appareils. Entrez le code pour continuer.",
            "resend_text": "Vous n'avez pas reçu le code ?",
            "resend_button": "Renvoyer",
            "resend_timer": "Renvoyer disponible dans ({{seconds}}s)",
            "back_button": "Retour"
          },
          "Modal close button": "Fermer",
          "Access to premium subscription in the app": "Accès à toutes les fonctionnalités Premium, statistiques et suppression des publicités dans l'application",
          "Family Subscription": "Abonnement familial",
          "Invite Member": "Inviter un membre",
          "Enter member ID": "Entrer l'ID du membre",
          "No family subscription found.": "Aucun abonnement familial trouvé.",
          "No family members added yet.": "Aucun membre de la famille ajouté pour l'instant.",
          "Remove": "Supprimer",
          "Leave Family Subscription": "Quitter l'abonnement familial",
          "Your premium access is expired, contact to your subscriptions head": "Votre accès premium a expiré, contactez le responsable de l'abonnement",
          "Success": "Succès",
          "You have successfully left the family subscription.": "Vous avez quitté l'abonnement familial avec succès.",
          "Error": "Erreur",
          "Failed to fetch family subscription info.": "Échec de la récupération des informations de l'abonnement familial.",
          "Failed to add family member. Please try again.": "Impossible d'ajouter un membre de la famille. Veuillez réessayer.",
          "Failed to remove family member. Please try again.": "Impossible de supprimer un membre de la famille. Veuillez réessayer.",
          "Failed to leave the family subscription.": "Échec de la sortie de l'abonnement familial.",
          "Only users with premium access can be the head of a family subscription.": "Seuls les utilisateurs avec accès premium peuvent être le chef d'un abonnement familial.",
          "You are already a member of another family subscription and cannot invite members.": "Vous êtes déjà membre d'un autre abonnement familial et vous ne pouvez pas inviter de nouveaux membres.",
          "Invitee not found.": "L'utilisateur que vous essayez d'inviter n'a pas été trouvé.",
          "Invitee is already part of another family subscription.": "L'utilisateur fait déjà partie d'un autre abonnement familial.",
          "No available slots left in your family subscription.": "Aucun créneau disponible dans votre abonnement familial.",
          "No family subscription found.": "Aucun abonnement familial trouvé.",
          "User is not a member of your family subscription.": "L'utilisateur n'est pas membre de votre abonnement familial.",
          "You do not have a family subscription.": "Vous n'avez pas d'abonnement familial.",
          "User not found.": "Utilisateur non trouvé.",
          "You are not part of a family subscription.": "Vous ne faites pas partie d'un abonnement familial.",
          "Error adding family member.": "Erreur lors de l'ajout du membre de la famille.",
          "Error fetching family info.": "Erreur lors de la récupération des informations sur l'abonnement familial.",
          "Error leaving family subscription.": "Erreur lors de la sortie de l'abonnement familial.",
          "Error removing family member.": "Erreur lors de la suppression du membre de la famille.",
          "You have left the family subscription.": "Vous avez quitté l'abonnement familial.",
          "User added to the family subscription.": "Utilisateur ajouté à l'abonnement familial.",
          "User removed from the family subscription.": "Utilisateur retiré de l'abonnement familial.",
          "Add Member": "Ajouter un membre",
          "Family head": "Chef d'abonnement",

          "SubscriptionOptions purchase for": "Acheter pour {{price}}",
          "Cancel anytime.": "Annulez à tout moment.",
          "One-time payment. Access forever.": "Paiement unique. Accès pour toujours.",
          "Product name monthly": "Abonnement premium mensuel",
          "Product name yearly": "Abonnement premium annuel",
          "Product name forever": "Premium à vie",

          "Something went wrong": "Oups... Quelque chose s'est mal passé...",
          "Reload page": "Recharger la page",

          "footer.privacy": "Politique de confidentialité",
          "footer.terms": "Conditions d'utilisation",
          "footer.contact-us": "Contactez-nous",

          "Manage subscription title": "Gérer l'abonnement",
          "Manage subscription next payment": "Date du prochain paiement:",
          "Manage subscription subscription status": "Statut de l'abonnement:",
          "Manage subscription subscription status Active": "Actif",
          "Manage subscription subscription status not Active": "Inactif",
          "Manage subscription next payment price": "Montant du paiement:",
          "Manage subscription cancel": "Annuler l'abonnement",
          "Manage subscription cancel confirmation text": "Êtes-vous sûr de vouloir annuler l'abonnement ? L'accès aux fonctionnalités Premium sera perdu immédiatement après l'annulation.",
          "Manage subscription cancel confirmation title": "Confirmer l'annulation de l'abonnement",
          "Manage subscription cancel result success": "Abonnement annulé",
          "Manage subscription cancel result fail": "Erreur lors de l'annulation de l'abonnement : {{error}}"
        

        }
      },
      "it": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Problemi con i pagamenti su Google Play",
            "description1": "A causa delle modifiche alle politiche di Google Play, siamo costretti a cambiare giurisdizione per continuare a utilizzare il sistema di pagamento di Google Play. Poiché Google non ha fornito tempo sufficiente per questo, non abbiamo avuto abbastanza tempo per rendere il processo di transizione senza interruzioni per i nostri utenti. Pertanto, a partire dal <b>26 dicembre 2024</b>, i pagamenti tramite il sistema di pagamento Google Play non funzioneranno per un po' di tempo. Inoltre, tutti gli abbonamenti esistenti creati prima di questa data e il cui periodo pagato è terminato non possono essere rinnovati fino a quando i pagamenti su Google Play non riprenderanno.",
            "description2": "Stiamo attualmente lavorando per <b>riprendere i pagamenti su Google Play il prima possibile</b>. Tuttavia, gran parte di questo problema è al di fuori del nostro controllo, quindi ci vorrà del tempo. Apprezziamo la vostra comprensione.",
            "description3": "Se avete domande o problemi, contattateci via e-mail all'indirizzo <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Dati dell'account",
          "Providers": "Metodi di accesso",
          "Logout": "Disconnettersi",
          "Enter promo title": "Codici promozionali",
          "Enter promo placeholder": "Inserisci il codice promozionale",
          "Enter promo button": "Inserire",
          "Promocode activated title": "Codice promozionale attivato",
          "Promocode activated you received": "Hai ricevuto:",
          "Promocode activated you received Premium until": "Abbonamento Premium fino al {{date}}",
          "Promocode not activated title": "Errore di attivazione del codice promozionale",
          "Promocode activation error unknown": "Errore sconosciuto",
          "Promocode activation error not found": "Codice promozionale non trovato",
          "Promocode activation error already used": "Il codice promozionale è già stato utilizzato",
          "Promocode activation error you already used": "Hai già utilizzato questo codice promozionale",
          "Login methods title": "Metodi di accesso",
          "Login methods no data": "Nessun metodo di accesso disponibile",
          "Login methods add method": "Aggiungi metodo di accesso",
          "Active subscription title": "Abbonamenti attivi",
          "Active subscription active untill": "Prossimo pagamento {{date}}",
          "Active subscription manage subscription": "Gestisci abbonamento",
          "Active subscription no subscriptions": "Nessun abbonamento attivo",
          "Active subscription per month": "Al mese",
          "Active subscription per year": "All'anno",
          "Active subscription forever": "Per sempre",
          "Active subscription subscription": "Abbonamento",
          "Active subscription subscription title": "Abbonamento Premium ({{type}})",
          "Active subscription no ads": "Senza pubblicità",
          "Active subscription one-time purchase": "Acquisto una tantum",
          "Active subscription promocode": "Codice promozionale",
          "SubscriptionOptions title": "Ottieni Premium",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} al mese",
          "SubscriptionOptions price yearly": "{{price}} all'anno",
          "SubscriptionOptions price forever": "{{price}} per sempre",
          "SubscriptionOptions monthly subscription": "Abbonamento mensile",
          "SubscriptionOptions yearly subscription": "Abbonamento annuale",
          "SubscriptionOptions forever subscription": "Acquisto una tantum",
          "SubscriptionOptions continue button": "Continua",
          "AuthMethods_welcome_title": "Benvenuto in StudyGe",
          "AuthMethods_sign_in_google": "Accedi con Google",
          "AuthMethods_sign_in_apple": "Accedi con Apple",
          "AuthMethods_sign_in_email": "Accedi con Email",
          "AuthMethods_or": "O",
          "AuthMethods_email_placeholder": "Indirizzo email",
          "AuthMethods_footer_text": "Continuando, accetti i seguenti termini:",
          "AuthMethods_terms_of_use": "Termini di servizio",
          "AuthMethods_and": "e",
          "AuthMethods_privacy_policy": "Politica sulla riservatezza",
          "AuthMethods_footer_text_end": "— e confermi di averli letti.",
          "AuthMethods_error": {
            "title": "Si è verificato un errore",
            "invalid_email": "Email non valida",
            "invalid_code": "Codice non valido. Riprova.",
            "auth_conflict": "Questo metodo di accesso è già collegato all'account",
            "auth_used": "Un altro utente ha già utilizzato questo metodo di accesso",
            "auth_not_found": "Utente non trovato",
            "auth_access": "Errore di accesso",
            "auth_failed": "Errore di autenticazione. Controlla i dati inseriti.",
            "auth_conflict": "Questo metodo di accesso è già collegato al tuo account o all'account di un altro utente.",
            "auth_invalid_token": "Il token di accesso fornito non è valido.",
            "auth_invalid_data": "Il server ha ricevuto dati non validi. Riprova.",
            "auth_server_error": "Si è verificato un errore sconosciuto sul server. Riprova più tardi.",
            "unknown": "Errore sconosciuto"
          },
          "VerificationCodeInput": {
            "title": "Codice di verifica",
            "description": "Un messaggio con il codice di verifica è stato inviato ai tuoi dispositivi. Inserisci il codice per continuare.",
            "resend_text": "Non hai ricevuto il codice?",
            "resend_button": "Richiedi di nuovo",
            "resend_timer": "Reinvio disponibile in ({{seconds}}s)",
            "back_button": "Indietro"
          },
          "Modal close button": "Chiudere",
          "Access to premium subscription in the app": "Accesso a tutte le funzionalità Premium, statistiche e rimozione degli annunci nell'app",
          "Family Subscription": "Abbonamento famiglia",
          "Invite Member": "Invita membro",
          "Enter member ID": "Inserisci ID del membro",
          "No family subscription found.": "Nessun abbonamento famiglia trovato.",
          "No family members added yet.": "Non sono stati aggiunti membri della famiglia.",
          "Remove": "Rimuovi",
          "Leave Family Subscription": "Lascia l'abbonamento famiglia",
          "Your premium access is expired, contact to your subscriptions head": "Il tuo accesso premium è scaduto, contatta l'amministratore dell'abbonamento",
          "Success": "Successo",
          "You have successfully left the family subscription.": "Hai lasciato con successo l'abbonamento famiglia.",
          "Error": "Errore",
          "Failed to fetch family subscription info.": "Impossibile recuperare le informazioni sull'abbonamento famiglia.",
          "Failed to add family member. Please try again.": "Impossibile aggiungere un membro della famiglia. Riprova.",
          "Failed to remove family member. Please try again.": "Impossibile rimuovere un membro della famiglia. Riprova.",
          "Failed to leave the family subscription.": "Impossibile lasciare l'abbonamento famiglia.",
          "Only users with premium access can be the head of a family subscription.": "Solo gli utenti con accesso premium possono essere a capo di un abbonamento familiare.",
          "You are already a member of another family subscription and cannot invite members.": "Sei già membro di un altro abbonamento familiare e non puoi invitare nuovi membri.",
          "Invitee not found.": "L'utente che stai cercando di invitare non è stato trovato.",
          "Invitee is already part of another family subscription.": "L'utente fa già parte di un altro abbonamento familiare.",
          "No available slots left in your family subscription.": "Non ci sono più posti disponibili nel tuo abbonamento familiare.",
          "No family subscription found.": "Nessun abbonamento familiare trovato.",
          "User is not a member of your family subscription.": "L'utente non è membro del tuo abbonamento familiare.",
          "You do not have a family subscription.": "Non hai un abbonamento familiare.",
          "User not found.": "Utente non trovato.",
          "You are not part of a family subscription.": "Non fai parte di un abbonamento familiare.",
          "Error adding family member.": "Errore durante l'aggiunta del membro della famiglia.",
          "Error fetching family info.": "Errore durante il recupero delle informazioni sull'abbonamento familiare.",
          "Error leaving family subscription.": "Errore durante l'uscita dall'abbonamento familiare.",
          "Error removing family member.": "Errore durante la rimozione del membro della famiglia.",
          "You have left the family subscription.": "Hai lasciato l'abbonamento familiare.",
          "User added to the family subscription.": "Utente aggiunto all'abbonamento familiare.",
          "User removed from the family subscription.": "Utente rimosso dall'abbonamento familiare.",
          "Add Member": "Aggiungi membro",
          "Family head": "Capo dell'abbonamento",

          "SubscriptionOptions purchase for": "Acquista per {{price}}",
          "Cancel anytime.": "Annulla in qualsiasi momento.",
          "One-time payment. Access forever.": "Pagamento unico. Accesso per sempre.",
          "Product name monthly": "Abbonamento premium mensile",
          "Product name yearly": "Abbonamento premium annuale",
          "Product name forever": "Premium per sempre",

          "Something went wrong": "Ops... Qualcosa è andato storto...",
          "Reload page": "Ricarica la pagina",

          "footer.privacy": "Informativa sulla privacy",
          "footer.terms": "Termini di utilizzo",
          "footer.contact-us": "Contattaci",

          "Manage subscription title": "Gestisci abbonamento",
          "Manage subscription next payment": "Data del prossimo pagamento:",
          "Manage subscription subscription status": "Stato dell'abbonamento:",
          "Manage subscription subscription status Active": "Attivo",
          "Manage subscription subscription status not Active": "Non attivo",
          "Manage subscription next payment price": "Importo del pagamento:",
          "Manage subscription cancel": "Annulla abbonamento",
          "Manage subscription cancel confirmation text": "Sei sicuro di voler annullare l'abbonamento? L'accesso alle funzionalità Premium sarà perso immediatamente dopo l'annullamento.",
          "Manage subscription cancel confirmation title": "Conferma annullamento abbonamento",
          "Manage subscription cancel result success": "Abbonamento annullato",
          "Manage subscription cancel result fail": "Errore durante l'annullamento dell'abbonamento: {{error}}"
       

        }
      },
      "ja": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Google Playでの支払いの問題",
            "description1": "Google Play のポリシー変更に伴い、Google Play 決済システムを引き続き利用するために管轄を変更せざるを得なくなりました。Google がこのための時間を十分に提供しなかったため、ユーザーにとってスムーズな移行プロセスを実現するための時間がありませんでした。そのため、<b>2024 年 12 月 26 日</b> より、Google Play 決済システムを通じた支払いはしばらくの間機能しなくなります。また、この日付より前に作成され、支払い期間が終了した既存のサブスクリプションは、Google Play の支払いが再開されるまで更新することができません。",
            "description2": "現在、Google Playでの支払いを<b>できるだけ早く再開する</b>ための取り組みを行っています。ただし、この問題に関しては当社の制御を超える要因が多く、一部時間がかかることをご了承ください。",
            "description3": "ご不明点や問題がある場合は、電子メールでお問い合わせください: <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "アカウントデータ",
          "Providers": "ログイン方法",
          "Logout": "ログアウト",
          "Enter promo title": "プロモーションコード",
          "Enter promo placeholder": "プロモーションコードを入力",
          "Enter promo button": "入力する",
          "Promocode activated title": "プロモーションコードが有効化されました",
          "Promocode activated you received": "受け取ったもの:",
          "Promocode activated you received Premium until": "プレミアムサブスクリプションは{{date}}まで有効",
          "Promocode not activated title": "プロモーションコードの有効化エラー",
          "Promocode activation error unknown": "不明なエラー",
          "Promocode activation error not found": "プロモーションコードが見つかりません",
          "Promocode activation error already used": "プロモーションコードは既に使用されています",
          "Promocode activation error you already used": "このプロモーションコードは既に使用されています",
          "Login methods title": "ログイン方法",
          "Login methods no data": "ログイン方法がありません",
          "Login methods add method": "ログイン方法を追加",
          "Active subscription title": "アクティブなサブスクリプション",
          "Active subscription active untill": "次の支払い {{date}}",
          "Active subscription manage subscription": "サブスクリプションの管理",
          "Active subscription no subscriptions": "アクティブなサブスクリプションはありません",
          "Active subscription per month": "毎月",
          "Active subscription per year": "毎年",
          "Active subscription forever": "永久",
          "Active subscription subscription": "サブスクリプション",
          "Active subscription subscription title": "プレミアムサブスクリプション ({{type}})",
          "Active subscription no ads": "広告なし",
          "Active subscription one-time purchase": "一回限りの購入",
          "Active subscription promocode": "プロモーションコード",
          "SubscriptionOptions title": "プレミアムを取得",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}}/月",
          "SubscriptionOptions price yearly": "{{price}}/年",
          "SubscriptionOptions price forever": "{{price}}/永久",
          "SubscriptionOptions monthly subscription": "月額サブスクリプション",
          "SubscriptionOptions yearly subscription": "年額サブスクリプション",
          "SubscriptionOptions forever subscription": "一回限りの購入",
          "SubscriptionOptions continue button": "続行",
          "AuthMethods_welcome_title": "StudyGeへようこそ",
          "AuthMethods_sign_in_google": "Googleでサインイン",
          "AuthMethods_sign_in_apple": "Appleでサインイン",
          "AuthMethods_sign_in_email": "メールでサインイン",
          "AuthMethods_or": "または",
          "AuthMethods_email_placeholder": "メールアドレス",
          "AuthMethods_footer_text": "続行すると、次の条件に同意したことになります:",
          "AuthMethods_terms_of_use": "利用規約",
          "AuthMethods_and": "および",
          "AuthMethods_privacy_policy": "プライバシーポリシー",
          "AuthMethods_footer_text_end": "— また、それを読んだことを確認します。",
          "AuthMethods_error": {
            "title": "エラーが発生しました",
            "invalid_email": "無効なメールアドレス",
            "invalid_code": "無効なコードです。再試行してください。",
            "auth_conflict": "このログイン方法は既にアカウントにリンクされています",
            "auth_used": "他のユーザーがこのログイン方法を既に使用しています",
            "auth_not_found": "ユーザーが見つかりません",
            "auth_access": "アクセスエラー",
            "auth_failed": "認証エラー。入力した情報を確認してください。",
            "auth_conflict": "このログイン方法は既にあなたのアカウントまたは他のユーザーのアカウントにリンクされています。",
            "auth_invalid_token": "提供されたアクセス トークンは無効です。",
            "auth_invalid_data": "サーバーが無効なデータを受信しました。再試行してください。",
            "auth_server_error": "サーバーで不明なエラーが発生しました。後でもう一度やり直してください。",
            "unknown": "不明なエラー"
          },
          "VerificationCodeInput": {
            "title": "確認コード",
            "description": "確認コードがデバイスに送信されました。続行するにはコードを入力してください。",
            "resend_text": "コードが届きませんか？",
            "resend_button": "再送信",
            "resend_timer": "{{seconds}}秒後に再送信可能",
            "back_button": "戻る"
          },
          "Modal close button": "閉じる",
          "Access to premium subscription in the app": "アプリ内のすべてのプレミアム機能、統計、および広告削除へのアクセス",
          "Family Subscription": "ファミリーサブスクリプション",
          "Invite Member": "メンバーを招待",
          "Enter member ID": "メンバーIDを入力",
          "No family subscription found.": "ファミリーサブスクリプションが見つかりません。",
          "No family members added yet.": "まだファミリーメンバーが追加されていません。",
          "Remove": "削除",
          "Leave Family Subscription": "ファミリーサブスクリプションを退会",
          "Your premium access is expired, contact to your subscriptions head": "プレミアムアクセスが期限切れです。サブスクリプションの責任者に連絡してください。",
          "Success": "成功",
          "You have successfully left the family subscription.": "ファミリーサブスクリプションを正常に退会しました。",
          "Error": "エラー",
          "Failed to fetch family subscription info.": "ファミリーサブスクリプション情報の取得に失敗しました。",
          "Failed to add family member. Please try again.": "ファミリーメンバーの追加に失敗しました。もう一度試してください。",
          "Failed to remove family member. Please try again.": "ファミリーメンバーの削除に失敗しました。もう一度試してください。",
          "Failed to leave the family subscription.": "ファミリーサブスクリプションの退会に失敗しました。",
          "Only users with premium access can be the head of a family subscription.": "プレミアムアクセスを持っているユーザーのみがファミリーサブスクリプションの責任者になれます。",
          "You are already a member of another family subscription and cannot invite members.": "あなたはすでに他のファミリーサブスクリプションのメンバーであり、メンバーを招待することはできません。",
          "Invitee not found.": "招待しようとしているユーザーが見つかりません。",
          "Invitee is already part of another family subscription.": "このユーザーはすでに別のファミリーサブスクリプションに参加しています。",
          "No available slots left in your family subscription.": "あなたのファミリーサブスクリプションには利用可能なスロットがありません。",
          "No family subscription found.": "ファミリーサブスクリプションが見つかりません。",
          "User is not a member of your family subscription.": "このユーザーはあなたのファミリーサブスクリプションのメンバーではありません。",
          "You do not have a family subscription.": "あなたはファミリーサブスクリプションに参加していません。",
          "User not found.": "ユーザーが見つかりません。",
          "You are not part of a family subscription.": "あなたはファミリーサブスクリプションの一員ではありません。",
          "Error adding family member.": "ファミリーメンバーの追加中にエラーが発生しました。",
          "Error fetching family info.": "ファミリーサブスクリプション情報の取得中にエラーが発生しました。",
          "Error leaving family subscription.": "ファミリーサブスクリプションから退出中にエラーが発生しました。",
          "Error removing family member.": "ファミリーメンバーの削除中にエラーが発生しました。",
          "You have left the family subscription.": "ファミリーサブスクリプションから退出しました。",
          "User added to the family subscription.": "ユーザーがファミリーサブスクリプションに追加されました。",
          "User removed from the family subscription.": "ユーザーがファミリーサブスクリプションから削除されました。",
          "Add Member": "メンバーを追加",
          "Family head": "サブスクリプションの責任者",

          "SubscriptionOptions purchase for": "{{price}}で購入",
          "Cancel anytime.": "いつでもキャンセル可能。",
          "One-time payment. Access forever.": "一括払い。永久アクセス。",
          "Product name monthly": "プレミアム月額プラン",
          "Product name yearly": "プレミアム年額プラン",
          "Product name forever": "プレミアム永久プラン",

          "Something went wrong": "おっと...何かがうまくいきませんでした...",
          "Reload page": "ページをリロードする",

          "footer.privacy": "プライバシーポリシー",
          "footer.terms": "利用規約",
          "footer.contact-us": "お問い合わせ",

          "Manage subscription title": "サブスクリプションの管理",
          "Manage subscription next payment": "次の支払日:",
          "Manage subscription subscription status": "サブスクリプションの状態:",
          "Manage subscription subscription status Active": "アクティブ",
          "Manage subscription subscription status not Active": "非アクティブ",
          "Manage subscription next payment price": "支払額:",
          "Manage subscription cancel": "サブスクリプションをキャンセル",
          "Manage subscription cancel confirmation text": "サブスクリプションをキャンセルしてもよろしいですか？キャンセル後、Premium 機能へのアクセスは直ちに失われます。",
          "Manage subscription cancel confirmation title": "サブスクリプションのキャンセルを確認",
          "Manage subscription cancel result success": "サブスクリプションがキャンセルされました",
          "Manage subscription cancel result fail": "サブスクリプションのキャンセルエラー: {{error}}"
        

        }
      },
      "kr": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Google Play 결제 문제",
            "description1": "Google Play 정책 변경으로 인해 Google Play 결제 시스템을 계속 사용하기 위해 관할권을 변경해야 했습니다. Google이 이에 대한 충분한 시간을 제공하지 않았기 때문에 사용자에게 원활한 전환 과정을 제공할 시간이 없었습니다. 따라서 <b>2024년 12월 26일</b>부터 Google Play 결제 시스템을 통한 결제가 일정 기간 동안 작동하지 않을 것입니다. 또한, 이 날짜 이전에 생성된 모든 기존 구독은 유료 기간이 종료된 경우 Google Play 결제가 재개될 때까지 갱신할 수 없습니다.",
            "description2": "현재 <b>Google Play 결제를 가능한 한 빨리 재개하기</b> 위해 노력하고 있습니다. 그러나 이 문제와 관련하여 우리 통제를 벗어난 부분이 많기 때문에 시간이 걸릴 것입니다. 여러분의 이해에 감사드립니다.",
            "description3": "문의 사항이나 문제가 있는 경우 이메일 <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>로 연락해 주세요."
        },
          "Account Data": "계정 데이터",
          "Providers": "로그인 방법",
          "Logout": "로그아웃",
          "Enter promo title": "프로모션 코드",
          "Enter promo placeholder": "프로모션 코드를 입력하세요",
          "Enter promo button": "입력",
          "Promocode activated title": "프로모션 코드 활성화됨",
          "Promocode activated you received": "받은 항목:",
          "Promocode activated you received Premium until": "{{date}}까지 프리미엄 구독",
          "Promocode not activated title": "프로모션 코드 활성화 오류",
          "Promocode activation error unknown": "알 수 없는 오류",
          "Promocode activation error not found": "프로모션 코드를 찾을 수 없음",
          "Promocode activation error already used": "프로모션 코드가 이미 사용되었습니다",
          "Promocode activation error you already used": "이 프로모션 코드를 이미 사용했습니다",
          "Login methods title": "로그인 방법",
          "Login methods no data": "사용 가능한 로그인 방법이 없습니다",
          "Login methods add method": "로그인 방법 추가",
          "Active subscription title": "활성 구독",
          "Active subscription active untill": "다음 결제 {{date}}",
          "Active subscription manage subscription": "구독 관리",
          "Active subscription no subscriptions": "활성 구독이 없습니다",
          "Active subscription per month": "월별",
          "Active subscription per year": "연별",
          "Active subscription forever": "영구",
          "Active subscription subscription": "구독",
          "Active subscription subscription title": "프리미엄 구독 ({{type}})",
          "Active subscription no ads": "광고 없음",
          "Active subscription one-time purchase": "일회성 구매",
          "Active subscription promocode": "프로모션 코드",
          "SubscriptionOptions title": "프리미엄 받기",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "월별 {{price}}",
          "SubscriptionOptions price yearly": "연별 {{price}}",
          "SubscriptionOptions price forever": "영구 {{price}}",
          "SubscriptionOptions monthly subscription": "월간 구독",
          "SubscriptionOptions yearly subscription": "연간 구독",
          "SubscriptionOptions forever subscription": "일회성 구매",
          "SubscriptionOptions continue button": "계속",
          "AuthMethods_welcome_title": "StudyGe에 오신 것을 환영합니다",
          "AuthMethods_sign_in_google": "Google로 로그인",
          "AuthMethods_sign_in_apple": "Apple로 로그인",
          "AuthMethods_sign_in_email": "이메일로 로그인",
          "AuthMethods_or": "또는",
          "AuthMethods_email_placeholder": "이메일 주소",
          "AuthMethods_footer_text": "계속하면 다음 조건에 동의하게 됩니다:",
          "AuthMethods_terms_of_use": "이용 약관",
          "AuthMethods_and": "및",
          "AuthMethods_privacy_policy": "개인정보 보호정책",
          "AuthMethods_footer_text_end": "— 그리고 이를 읽었다는 것을 확인합니다.",
          "AuthMethods_error": {
            "title": "오류가 발생했습니다",
            "invalid_email": "잘못된 이메일",
            "invalid_code": "잘못된 코드입니다. 다시 시도하세요.",
            "auth_conflict": "이 로그인 방법은 이미 계정에 연결되어 있습니다",
            "auth_used": "다른 사용자가 이 로그인 방법을 이미 사용했습니다",
            "auth_not_found": "사용자를 찾을 수 없습니다",
            "auth_access": "액세스 오류",
            "auth_failed": "인증 오류. 입력한 정보가 정확한지 확인하세요.",
            "auth_conflict": "이 인증 방법은 이미 귀하의 계정 또는 다른 사용자의 계정에 연결되어 있습니다.",
            "auth_invalid_token": "제공된 액세스 토큰이 유효하지 않습니다.",
            "auth_invalid_data": "서버에서 잘못된 데이터를 수신했습니다. 다시 시도하세요.",
            "auth_server_error": "서버에서 알 수 없는 오류가 발생했습니다. 나중에 다시 시도하세요.",
            "unknown": "알 수 없는 오류"
          },
          "VerificationCodeInput": {
            "title": "인증 코드",
            "description": "인증 코드가 포함된 메시지가 귀하의 장치로 전송되었습니다. 계속하려면 코드를 입력하세요.",
            "resend_text": "코드를 받지 못하셨나요?",
            "resend_button": "다시 요청",
            "resend_timer": "{{seconds}}초 후 다시 전송 가능",
            "back_button": "뒤로"
          },
          "Modal close button": "닫기",
          "Access to premium subscription in the app": "앱에서 모든 프리미엄 기능, 통계 및 광고 제거에 대한 액세스",
          "Family Subscription": "가족 구독",
          "Invite Member": "회원 초대",
          "Enter member ID": "회원 ID 입력",
          "No family subscription found.": "가족 구독을 찾을 수 없습니다.",
          "No family members added yet.": "아직 가족 구성원이 추가되지 않았습니다.",
          "Remove": "제거",
          "Leave Family Subscription": "가족 구독 나가기",
          "Your premium access is expired, contact to your subscriptions head": "프리미엄 액세스가 만료되었습니다. 구독 책임자에게 문의하세요.",
          "Success": "성공",
          "You have successfully left the family subscription.": "가족 구독을 성공적으로 나갔습니다.",
          "Error": "오류",
          "Failed to fetch family subscription info.": "가족 구독 정보를 가져오는 데 실패했습니다.",
          "Failed to add family member. Please try again.": "가족 구성원을 추가하지 못했습니다. 다시 시도하십시오.",
          "Failed to remove family member. Please try again.": "가족 구성원을 제거하지 못했습니다. 다시 시도하십시오.",
          "Failed to leave the family subscription.": "가족 구독을 나가는 데 실패했습니다.",
          "Only users with premium access can be the head of a family subscription.": "프리미엄 액세스를 가진 사용자만 가족 구독의 책임자가 될 수 있습니다.",
          "You are already a member of another family subscription and cannot invite members.": "귀하는 이미 다른 가족 구독의 멤버이며, 멤버를 초대할 수 없습니다.",
          "Invitee not found.": "초대하려는 사용자를 찾을 수 없습니다.",
          "Invitee is already part of another family subscription.": "해당 사용자는 이미 다른 가족 구독에 참여하고 있습니다.",
          "No available slots left in your family subscription.": "귀하의 가족 구독에 남은 사용 가능한 슬롯이 없습니다.",
          "No family subscription found.": "가족 구독을 찾을 수 없습니다.",
          "User is not a member of your family subscription.": "해당 사용자는 귀하의 가족 구독의 멤버가 아닙니다.",
          "You do not have a family subscription.": "귀하는 가족 구독에 가입되어 있지 않습니다.",
          "User not found.": "사용자를 찾을 수 없습니다.",
          "You are not part of a family subscription.": "귀하는 가족 구독의 일원이 아닙니다.",
          "Error adding family member.": "가족 멤버를 추가하는 중 오류가 발생했습니다.",
          "Error fetching family info.": "가족 구독 정보를 가져오는 중 오류가 발생했습니다.",
          "Error leaving family subscription.": "가족 구독을 나가는 중 오류가 발생했습니다.",
          "Error removing family member.": "가족 멤버를 제거하는 중 오류가 발생했습니다.",
          "You have left the family subscription.": "가족 구독에서 나갔습니다.",
          "User added to the family subscription.": "사용자가 가족 구독에 추가되었습니다.",
          "User removed from the family subscription.": "사용자가 가족 구독에서 제거되었습니다.",
          "Add Member": "멤버 추가",
          "Family head": "구독 책임자",

          "SubscriptionOptions purchase for": "{{price}}에 구매",
          "Cancel anytime.": "언제든지 취소 가능.",
          "One-time payment. Access forever.": "일회성 결제. 영구 접근.",
          "Product name monthly": "프리미엄 월간 구독",
          "Product name yearly": "프리미엄 연간 구독",
          "Product name forever": "프리미엄 영구",
          "Something went wrong": "이런... 문제가 발생했습니다...",
          "Reload page": "페이지 새로고침",

          "footer.privacy": "개인정보 보호정책",
          "footer.terms": "이용 약관",
          "footer.contact-us": "문의하기",

          "Manage subscription title": "구독 관리",
          "Manage subscription next payment": "다음 결제 날짜:",
          "Manage subscription subscription status": "구독 상태:",
          "Manage subscription subscription status Active": "활성화됨",
          "Manage subscription subscription status not Active": "비활성화됨",
          "Manage subscription next payment price": "결제 금액:",
          "Manage subscription cancel": "구독 취소",
          "Manage subscription cancel confirmation text": "구독을 취소하시겠습니까? 구독 취소 후에는 Premium 기능에 즉시 액세스할 수 없습니다.",
          "Manage subscription cancel confirmation title": "구독 취소 확인",
          "Manage subscription cancel result success": "구독이 취소되었습니다",
          "Manage subscription cancel result fail": "구독 취소 오류: {{error}}"
        

        }
      },
      "nl": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Problemen met betalingen op Google Play",
            "description1": "Vanwege wijzigingen in het beleid van Google Play zijn we genoodzaakt om van jurisdictie te veranderen om het betalingssysteem van Google Play te blijven gebruiken. Aangezien Google hiervoor onvoldoende tijd heeft geboden, hadden we niet genoeg tijd om het overgangsproces voor onze gebruikers naadloos te maken. Daarom zullen betalingen via het betalingssysteem van Google Play vanaf <b>26 december 2024</b> tijdelijk niet werken. Bovendien kunnen alle bestaande abonnementen die vóór deze datum zijn gemaakt en waarvan de betaalde periode is verstreken, niet worden verlengd totdat betalingen via Google Play zijn hervat.",
            "description2": "We werken momenteel aan <b>het zo snel mogelijk hervatten van betalingen via Google Play</b>. Veel van deze kwestie ligt echter buiten onze controle, dus het zal wat tijd kosten. We hopen op uw begrip.",
            "description3": "Als u vragen of problemen heeft, neem dan contact met ons op via e-mail op <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Accountgegevens",
          "Providers": "Inlogmethoden",
          "Logout": "Uitloggen",
          "Enter promo title": "Promocodes",
          "Enter promo placeholder": "Voer promotiecode in",
          "Enter promo button": "Invoeren",
          "Promocode activated title": "Promotiecode geactiveerd",
          "Promocode activated you received": "Je hebt ontvangen:",
          "Promocode activated you received Premium until": "Premium-abonnement tot {{date}}",
          "Promocode not activated title": "Fout bij activeren van promotiecode",
          "Promocode activation error unknown": "Onbekende fout",
          "Promocode activation error not found": "Promotiecode niet gevonden",
          "Promocode activation error already used": "Promotiecode is al gebruikt",
          "Promocode activation error you already used": "Je hebt deze promotiecode al gebruikt",
          "Login methods title": "Inlogmethoden",
          "Login methods no data": "Geen inlogmethoden beschikbaar",
          "Login methods add method": "Inlogmethode toevoegen",
          "Active subscription title": "Actieve abonnementen",
          "Active subscription active untill": "Volgende betaling {{date}}",
          "Active subscription manage subscription": "Abonnement beheren",
          "Active subscription no subscriptions": "Geen actieve abonnementen",
          "Active subscription per month": "Per maand",
          "Active subscription per year": "Per jaar",
          "Active subscription forever": "Voor altijd",
          "Active subscription subscription": "Abonnement",
          "Active subscription subscription title": "Premium-abonnement ({{type}})",
          "Active subscription no ads": "Geen advertenties",
          "Active subscription one-time purchase": "Eenmalige aankoop",
          "Active subscription promocode": "Promotiecode",
          "SubscriptionOptions title": "Premium verkrijgen",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} per maand",
          "SubscriptionOptions price yearly": "{{price}} per jaar",
          "SubscriptionOptions price forever": "{{price}} voor altijd",
          "SubscriptionOptions monthly subscription": "Maandelijks abonnement",
          "SubscriptionOptions yearly subscription": "Jaarlijks abonnement",
          "SubscriptionOptions forever subscription": "Eenmalige aankoop",
          "SubscriptionOptions continue button": "Doorgaan",
          "AuthMethods_welcome_title": "Welkom bij StudyGe",
          "AuthMethods_sign_in_google": "Inloggen met Google",
          "AuthMethods_sign_in_apple": "Inloggen met Apple",
          "AuthMethods_sign_in_email": "Inloggen met e-mail",
          "AuthMethods_or": "OF",
          "AuthMethods_email_placeholder": "E-mailadres",
          "AuthMethods_footer_text": "Door door te gaan, gaat u akkoord met de volgende voorwaarden:",
          "AuthMethods_terms_of_use": "Gebruiksvoorwaarden",
          "AuthMethods_and": "en",
          "AuthMethods_privacy_policy": "Privacybeleid",
          "AuthMethods_footer_text_end": "— en bevestigt u dat u ze heeft gelezen.",
          "AuthMethods_error": {
            "title": "Er is een fout opgetreden",
            "invalid_email": "Ongeldige e-mail",
            "invalid_code": "Ongeldige code. Probeer het opnieuw.",
            "auth_conflict": "Deze inlogmethode is al gekoppeld aan het account",
            "auth_used": "Een andere gebruiker heeft deze inlogmethode al gebruikt",
            "auth_not_found": "Gebruiker niet gevonden",
            "auth_access": "Toegangsfout",
            "auth_failed": "Authenticatiefout. Controleer uw gegevens.",
            "auth_conflict": "Deze inlogmethode is al gekoppeld aan uw account of aan het account van een andere gebruiker.",
            "auth_invalid_token": "De verstrekte toegangstoken is ongeldig.",
            "auth_invalid_data": "De server heeft ongeldige gegevens ontvangen. Probeer het opnieuw.",
            "auth_server_error": "Er is een onbekende fout opgetreden op de server. Probeer het later opnieuw.",
            "unknown": "Onbekende fout"
          },
          "VerificationCodeInput": {
            "title": "Verificatiecode",
            "description": "Een bericht met de verificatiecode is naar uw apparaten verzonden. Voer de code in om door te gaan.",
            "resend_text": "Geen code ontvangen?",
            "resend_button": "Opnieuw verzenden",
            "resend_timer": "Opnieuw verzenden beschikbaar in ({{seconds}}s)",
            "back_button": "Terug"
          },
          "Modal close button": "Sluiten",
          "Access to premium subscription in the app": "Toegang tot alle Premium-functies, statistieken en advertentieverwijdering in de app",
          "Family Subscription": "Gezinsabonnement",
          "Invite Member": "Lid uitnodigen",
          "Enter member ID": "Voer lid-ID in",
          "No family subscription found.": "Geen gezinsabonnement gevonden.",
          "No family members added yet.": "Nog geen gezinsleden toegevoegd.",
          "Remove": "Verwijderen",
          "Leave Family Subscription": "Gezinsabonnement verlaten",
          "Your premium access is expired, contact to your subscriptions head": "Uw premiumtoegang is verlopen, neem contact op met de abonnementbeheerder",
          "Success": "Succes",
          "You have successfully left the family subscription.": "U hebt het gezinsabonnement succesvol verlaten.",
          "Error": "Fout",
          "Failed to fetch family subscription info.": "Kon gezinsabonnementinformatie niet ophalen.",
          "Failed to add family member. Please try again.": "Het toevoegen van een gezinslid is mislukt. Probeer het opnieuw.",
          "Failed to remove family member. Please try again.": "Het verwijderen van een gezinslid is mislukt. Probeer het opnieuw.",
          "Failed to leave the family subscription.": "Het verlaten van het gezinsabonnement is mislukt.",
          "Only users with premium access can be the head of a family subscription.": "Alleen gebruikers met premium toegang kunnen het hoofd zijn van een gezinsabonnement.",
          "You are already a member of another family subscription and cannot invite members.": "Je bent al lid van een ander gezinsabonnement en kunt geen leden uitnodigen.",
          "Invitee not found.": "De gebruiker die je probeert uit te nodigen is niet gevonden.",
          "Invitee is already part of another family subscription.": "De gebruiker maakt al deel uit van een ander gezinsabonnement.",
          "No available slots left in your family subscription.": "Geen beschikbare plaatsen meer in je gezinsabonnement.",
          "No family subscription found.": "Geen gezinsabonnement gevonden.",
          "User is not a member of your family subscription.": "De gebruiker is geen lid van je gezinsabonnement.",
          "You do not have a family subscription.": "Je hebt geen gezinsabonnement.",
          "User not found.": "Gebruiker niet gevonden.",
          "You are not part of a family subscription.": "Je maakt geen deel uit van een gezinsabonnement.",
          "Error adding family member.": "Fout bij het toevoegen van gezinslid.",
          "Error fetching family info.": "Fout bij het ophalen van gezinsabonnementsinformatie.",
          "Error leaving family subscription.": "Fout bij het verlaten van het gezinsabonnement.",
          "Error removing family member.": "Fout bij het verwijderen van gezinslid.",
          "You have left the family subscription.": "Je hebt het gezinsabonnement verlaten.",
          "User added to the family subscription.": "Gebruiker toegevoegd aan het gezinsabonnement.",
          "User removed from the family subscription.": "Gebruiker verwijderd uit het gezinsabonnement.",
          "Add Member": "Lid toevoegen",
          "Family head": "Abonnementhoofd",

          "SubscriptionOptions purchase for": "Koop voor {{price}}",
          "Cancel anytime.": "Op elk moment annuleren.",
          "One-time payment. Access forever.": "Eenmalige betaling. Toegang voor altijd.",
          "Product name monthly": "Premium abonnement maandelijks",
          "Product name yearly": "Premium abonnement jaarlijks",
          "Product name forever": "Premium voor altijd",
          "Something went wrong": "Oeps... Er is iets misgegaan...",
          "Reload page": "Pagina opnieuw laden",

          "footer.privacy": "Privacybeleid",
          "footer.terms": "Gebruiksvoorwaarden",
          "footer.contact-us": "Neem contact met ons op",

          "Manage subscription title": "Abonnement beheren",
          "Manage subscription next payment": "Volgende betalingsdatum:",
          "Manage subscription subscription status": "Abonnementsstatus:",
          "Manage subscription subscription status Active": "Actief",
          "Manage subscription subscription status not Active": "Niet actief",
          "Manage subscription next payment price": "Betalingsbedrag:",
          "Manage subscription cancel": "Abonnement annuleren",
          "Manage subscription cancel confirmation text": "Weet u zeker dat u het abonnement wilt annuleren? Toegang tot Premium-functies wordt direct na annulering verloren.",
          "Manage subscription cancel confirmation title": "Bevestig annulering van abonnement",
          "Manage subscription cancel result success": "Abonnement geannuleerd",
          "Manage subscription cancel result fail": "Fout bij annuleren abonnement: {{error}}"
        

        }
      },
      "pl": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Problemy z płatnościami na Google Play",
            "description1": "Z powodu zmian w polityce Google Play jesteśmy zmuszeni zmienić jurysdykcję, aby kontynuować korzystanie z systemu płatności Google Play. Ponieważ Google nie zapewniło wystarczającej ilości czasu na tę zmianę, nie mieliśmy wystarczająco dużo czasu, aby proces przejścia był płynny dla naszych użytkowników. Dlatego od <b>26 grudnia 2024 roku</b> płatności za pośrednictwem systemu płatności Google Play nie będą działać przez pewien czas. Ponadto wszystkie istniejące subskrypcje utworzone przed tą datą, których opłacony okres dobiegł końca, nie będą mogły zostać odnowione, dopóki płatności za pośrednictwem Google Play nie zostaną wznowione.",
            "description2": "Obecnie pracujemy nad tym, aby <b>płatności za pośrednictwem Google Play zostały wznowione tak szybko, jak to możliwe</b>. Jednak wiele w tej kwestii zależy od czynników niezależnych od nas, więc może to zająć trochę czasu. Prosimy o wyrozumiałość.",
            "description3": "W razie jakichkolwiek pytań lub problemów prosimy o kontakt mailowy na adres <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Dane konta",
          "Providers": "Metody logowania",
          "Logout": "Wyloguj się",
          "Enter promo title": "Kody promocyjne",
          "Enter promo placeholder": "Wprowadź kod promocyjny",
          "Enter promo button": "Wprowadź",
          "Promocode activated title": "Kod promocyjny aktywowany",
          "Promocode activated you received": "Otrzymałeś:",
          "Promocode activated you received Premium until": "Abonament Premium do {{date}}",
          "Promocode not activated title": "Błąd aktywacji kodu promocyjnego",
          "Promocode activation error unknown": "Nieznany błąd",
          "Promocode activation error not found": "Nie znaleziono kodu promocyjnego",
          "Promocode activation error already used": "Kod promocyjny został już użyty",
          "Promocode activation error you already used": "Już użyłeś tego kodu promocyjnego",
          "Login methods title": "Metody logowania",
          "Login methods no data": "Brak dostępnych metod logowania",
          "Login methods add method": "Dodaj metodę logowania",
          "Active subscription title": "Aktywne subskrypcje",
          "Active subscription active untill": "Następna płatność {{date}}",
          "Active subscription manage subscription": "Zarządzaj subskrypcją",
          "Active subscription no subscriptions": "Brak aktywnych subskrypcji",
          "Active subscription per month": "Miesięcznie",
          "Active subscription per year": "Rocznie",
          "Active subscription forever": "Na zawsze",
          "Active subscription subscription": "Subskrypcja",
          "Active subscription subscription title": "Subskrypcja Premium ({{type}})",
          "Active subscription no ads": "Bez reklam",
          "Active subscription one-time purchase": "Zakup jednorazowy",
          "Active subscription promocode": "Kod promocyjny",
          "SubscriptionOptions title": "Uzyskaj Premium",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} miesięcznie",
          "SubscriptionOptions price yearly": "{{price}} rocznie",
          "SubscriptionOptions price forever": "{{price}} na zawsze",
          "SubscriptionOptions monthly subscription": "Subskrypcja miesięczna",
          "SubscriptionOptions yearly subscription": "Subskrypcja roczna",
          "SubscriptionOptions forever subscription": "Zakup jednorazowy",
          "SubscriptionOptions continue button": "Kontynuuj",
          "AuthMethods_welcome_title": "Witamy w StudyGe",
          "AuthMethods_sign_in_google": "Zaloguj się przez Google",
          "AuthMethods_sign_in_apple": "Zaloguj się przez Apple",
          "AuthMethods_sign_in_email": "Zaloguj się przez e-mail",
          "AuthMethods_or": "LUB",
          "AuthMethods_email_placeholder": "Adres e-mail",
          "AuthMethods_footer_text": "Kontynuując, zgadzasz się na następujące warunki:",
          "AuthMethods_terms_of_use": "Warunki korzystania",
          "AuthMethods_and": "i",
          "AuthMethods_privacy_policy": "Polityka prywatności",
          "AuthMethods_footer_text_end": "— i potwierdzasz, że je przeczytałeś.",
          "AuthMethods_error": {
            "title": "Wystąpił błąd",
            "invalid_email": "Nieprawidłowy e-mail",
            "invalid_code": "Nieprawidłowy kod. Spróbuj ponownie.",
            "auth_conflict": "Ta metoda logowania jest już powiązana z kontem",
            "auth_used": "Inny użytkownik już użył tej metody logowania",
            "auth_not_found": "Nie znaleziono użytkownika",
            "auth_access": "Błąd dostępu",
            "auth_failed": "Błąd uwierzytelniania. Sprawdź poprawność wprowadzonych danych.",
            "auth_conflict": "Ta metoda logowania jest już powiązana z Twoim kontem lub kontem innego użytkownika.",
            "auth_invalid_token": "Podany token dostępu jest nieprawidłowy.",
            "auth_invalid_data": "Serwer otrzymał nieprawidłowe dane. Spróbuj ponownie.",
            "auth_server_error": "Na serwerze wystąpił nieznany błąd. Spróbuj ponownie później.",
            "unknown": "Nieznany błąd"
          },
          "VerificationCodeInput": {
            "title": "Kod weryfikacyjny",
            "description": "Wiadomość z kodem weryfikacyjnym została wysłana na Twoje urządzenia. Wprowadź kod, aby kontynuować.",
            "resend_text": "Nie otrzymałeś kodu?",
            "resend_button": "Poproś ponownie",
            "resend_timer": "Ponowne wysłanie dostępne za ({{seconds}} s)",
            "back_button": "Wstecz"
          },
          "Modal close button": "Zamknij",
          "Access to premium subscription in the app": "Dostęp do wszystkich funkcji Premium, statystyk i usuwania reklam w aplikacji",
          "Family Subscription": "Subskrypcja rodzinna",
          "Invite Member": "Zaproś członka",
          "Enter member ID": "Wprowadź ID członka",
          "No family subscription found.": "Nie znaleziono subskrypcji rodzinnej.",
          "No family members added yet.": "Nie dodano jeszcze członków rodziny.",
          "Remove": "Usuń",
          "Leave Family Subscription": "Opuść subskrypcję rodzinną",
          "Your premium access is expired, contact to your subscriptions head": "Twój dostęp premium wygasł, skontaktuj się z administratorem subskrypcji",
          "Success": "Sukces",
          "You have successfully left the family subscription.": "Pomyślnie opuściłeś subskrypcję rodzinną.",
          "Error": "Błąd",
          "Failed to fetch family subscription info.": "Nie udało się pobrać informacji o subskrypcji rodzinnej.",
          "Failed to add family member. Please try again.": "Nie udało się dodać członka rodziny. Spróbuj ponownie.",
          "Failed to remove family member. Please try again.": "Nie udało się usunąć członka rodziny. Spróbuj ponownie.",
          "Failed to leave the family subscription.": "Nie udało się opuścić subskrypcji rodzinnej.",
          "Only users with premium access can be the head of a family subscription.": "Tylko użytkownicy z dostępem premium mogą być głową subskrypcji rodzinnej.",
          "You are already a member of another family subscription and cannot invite members.": "Jesteś już członkiem innej subskrypcji rodzinnej i nie możesz zapraszać nowych członków.",
          "Invitee not found.": "Użytkownik, którego próbujesz zaprosić, nie został znaleziony.",
          "Invitee is already part of another family subscription.": "Użytkownik jest już częścią innej subskrypcji rodzinnej.",
          "No available slots left in your family subscription.": "Brak dostępnych miejsc w twojej subskrypcji rodzinnej.",
          "No family subscription found.": "Nie znaleziono subskrypcji rodzinnej.",
          "User is not a member of your family subscription.": "Użytkownik nie jest członkiem twojej subskrypcji rodzinnej.",
          "You do not have a family subscription.": "Nie masz subskrypcji rodzinnej.",
          "User not found.": "Nie znaleziono użytkownika.",
          "You are not part of a family subscription.": "Nie jesteś częścią subskrypcji rodzinnej.",
          "Error adding family member.": "Błąd podczas dodawania członka rodziny.",
          "Error fetching family info.": "Błąd podczas pobierania informacji o subskrypcji rodzinnej.",
          "Error leaving family subscription.": "Błąd podczas opuszczania subskrypcji rodzinnej.",
          "Error removing family member.": "Błąd podczas usuwania członka rodziny.",
          "You have left the family subscription.": "Opuszczono subskrypcję rodzinną.",
          "User added to the family subscription.": "Użytkownik dodany do subskrypcji rodzinnej.",
          "User removed from the family subscription.": "Użytkownik usunięty z subskrypcji rodzinnej.",
          "Add Member": "Dodaj członka",
          "Family head": "Głowa subskrypcji",

          "SubscriptionOptions purchase for": "Zakup za {{price}}",
          "Cancel anytime.": "Anuluj w dowolnym momencie.",
          "One-time payment. Access forever.": "Jednorazowa płatność. Dostęp na zawsze.",
          "Product name monthly": "Miesięczna subskrypcja premium",
          "Product name yearly": "Roczna subskrypcja premium",
          "Product name forever": "Premium na zawsze",
          "Something went wrong": "Ups... Coś poszło nie tak...",
          "Reload page": "Odśwież stronę",

          "footer.privacy": "Polityka prywatności",
          "footer.terms": "Warunki użytkowania",
          "footer.contact-us": "Skontaktuj się z nami",

          "Manage subscription title": "Zarządzaj subskrypcją",
          "Manage subscription next payment": "Data następnej płatności:",
          "Manage subscription subscription status": "Status subskrypcji:",
          "Manage subscription subscription status Active": "Aktywna",
          "Manage subscription subscription status not Active": "Nieaktywna",
          "Manage subscription next payment price": "Kwota płatności:",
          "Manage subscription cancel": "Anuluj subskrypcję",
          "Manage subscription cancel confirmation text": "Czy na pewno chcesz anulować subskrypcję? Dostęp do funkcji Premium zostanie natychmiast utracony po anulowaniu.",
          "Manage subscription cancel confirmation title": "Potwierdź anulowanie subskrypcji",
          "Manage subscription cancel result success": "Subskrypcja anulowana",
          "Manage subscription cancel result fail": "Błąd podczas anulowania subskrypcji: {{error}}"
       

        }
      },
      "pt": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Problemas com pagamentos no Google Play",
            "description1": "Devido a mudanças nas políticas do Google Play, somos forçados a mudar a jurisdição para continuar utilizando o sistema de pagamentos do Google Play. Como o Google não forneceu tempo suficiente para isso, não tivemos tempo de tornar o processo de transição fluido para nossos usuários. Portanto, a partir de <b>26 de dezembro de 2024</b>, os pagamentos pelo sistema de pagamento do Google Play não funcionarão por algum tempo. Além disso, todas as assinaturas existentes criadas antes dessa data, cujo período pago tenha terminado, não poderão ser renovadas até que os pagamentos pelo Google Play sejam retomados.",
            "description2": "Estamos atualmente trabalhando para <b>retomar os pagamentos pelo Google Play o mais rápido possível</b>. No entanto, grande parte desta questão está fora do nosso controle, então levará algum tempo. Agradecemos pela sua compreensão.",
            "description3": "Se você tiver alguma dúvida ou problema, entre em contato conosco pelo e-mail <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Dados da conta",
          "Providers": "Métodos de login",
          "Logout": "Sair da conta",
          "Enter promo title": "Códigos promocionais",
          "Enter promo placeholder": "Insira o código promocional",
          "Enter promo button": "Inserir",
          "Promocode activated title": "Código promocional ativado",
          "Promocode activated you received": "Você recebeu:",
          "Promocode activated you received Premium until": "Assinatura Premium até {{date}}",
          "Promocode not activated title": "Erro na ativação do código promocional",
          "Promocode activation error unknown": "Erro desconhecido",
          "Promocode activation error not found": "Código promocional não encontrado",
          "Promocode activation error already used": "O código promocional já foi utilizado",
          "Promocode activation error you already used": "Você já utilizou este código promocional",
          "Login methods title": "Métodos de login",
          "Login methods no data": "Não há métodos de login disponíveis",
          "Login methods add method": "Adicionar método de login",
          "Active subscription title": "Assinaturas ativas",
          "Active subscription active untill": "Próximo pagamento {{date}}",
          "Active subscription manage subscription": "Gerenciar assinatura",
          "Active subscription no subscriptions": "Nenhuma assinatura ativa",
          "Active subscription per month": "Por mês",
          "Active subscription per year": "Por ano",
          "Active subscription forever": "Para sempre",
          "Active subscription subscription": "Assinatura",
          "Active subscription subscription title": "Assinatura Premium ({{type}})",
          "Active subscription no ads": "Sem anúncios",
          "Active subscription one-time purchase": "Compra única",
          "Active subscription promocode": "Código promocional",
          "SubscriptionOptions title": "Obter Premium",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} por mês",
          "SubscriptionOptions price yearly": "{{price}} por ano",
          "SubscriptionOptions price forever": "{{price}} para sempre",
          "SubscriptionOptions monthly subscription": "Assinatura mensal",
          "SubscriptionOptions yearly subscription": "Assinatura anual",
          "SubscriptionOptions forever subscription": "Compra única",
          "SubscriptionOptions continue button": "Continuar",
          "AuthMethods_welcome_title": "Bem-vindo ao StudyGe",
          "AuthMethods_sign_in_google": "Entrar com Google",
          "AuthMethods_sign_in_apple": "Entrar com Apple",
          "AuthMethods_sign_in_email": "Entrar com Email",
          "AuthMethods_or": "OU",
          "AuthMethods_email_placeholder": "Endereço de e-mail",
          "AuthMethods_footer_text": "Ao continuar, você concorda com os seguintes termos:",
          "AuthMethods_terms_of_use": "Termos de serviço",
          "AuthMethods_and": "e",
          "AuthMethods_privacy_policy": "Política de privacidade",
          "AuthMethods_footer_text_end": "— e confirma que os leu.",
          "AuthMethods_error": {
            "title": "Ocorreu um erro",
            "invalid_email": "E-mail inválido",
            "invalid_code": "Código inválido. Tente novamente.",
            "auth_conflict": "Este método de login já está vinculado à conta",
            "auth_used": "Outro usuário já utilizou este método de login",
            "auth_not_found": "Usuário não encontrado",
            "auth_access": "Erro de acesso",
            "auth_failed": "Erro de autenticação. Verifique se os dados estão corretos.",
            "auth_conflict": "Este método de login já está vinculado à sua conta ou à conta de outro usuário.",
            "auth_invalid_token": "O token de acesso fornecido é inválido.",
            "auth_invalid_data": "O servidor recebeu dados inválidos. Tente novamente.",
            "auth_server_error": "Ocorreu um erro desconhecido no servidor. Tente novamente mais tarde.",
            "unknown": "Erro desconhecido"
          },
          "VerificationCodeInput": {
            "title": "Código de verificação",
            "description": "Uma mensagem com o código de verificação foi enviada para seus dispositivos. Insira o código para continuar.",
            "resend_text": "Não recebeu o código?",
            "resend_button": "Solicitar novamente",
            "resend_timer": "Reenvio disponível em ({{seconds}}s)",
            "back_button": "Voltar"
          },
          "Modal close button": "Fechar",
          "Access to premium subscription in the app": "Acesso a todos os recursos Premium, estatísticas e remoção de anúncios no aplicativo",
          "Family Subscription": "Assinatura familiar",
          "Invite Member": "Convidar membro",
          "Enter member ID": "Insira o ID do membro",
          "No family subscription found.": "Nenhuma assinatura familiar encontrada.",
          "No family members added yet.": "Nenhum membro da família foi adicionado.",
          "Remove": "Remover",
          "Leave Family Subscription": "Sair da assinatura familiar",
          "Your premium access is expired, contact to your subscriptions head": "Seu acesso premium expirou, entre em contato com o administrador da assinatura",
          "Success": "Sucesso",
          "You have successfully left the family subscription.": "Você saiu com sucesso da assinatura familiar.",
          "Error": "Erro",
          "Failed to fetch family subscription info.": "Falha ao obter informações da assinatura familiar.",
          "Failed to add family member. Please try again.": "Falha ao adicionar membro da família. Tente novamente.",
          "Failed to remove family member. Please try again.": "Falha ao remover membro da família. Tente novamente.",
          "Failed to leave the family subscription.": "Falha ao sair da assinatura familiar.",
          "Only users with premium access can be the head of a family subscription.": "Somente usuários com acesso premium podem ser o chefe de uma assinatura familiar.",
          "You are already a member of another family subscription and cannot invite members.": "Você já é membro de outra assinatura familiar e não pode convidar novos membros.",
          "Invitee not found.": "O usuário que você está tentando convidar não foi encontrado.",
          "Invitee is already part of another family subscription.": "O usuário já faz parte de outra assinatura familiar.",
          "No available slots left in your family subscription.": "Não há vagas disponíveis em sua assinatura familiar.",
          "No family subscription found.": "Nenhuma assinatura familiar encontrada.",
          "User is not a member of your family subscription.": "O usuário não é membro da sua assinatura familiar.",
          "You do not have a family subscription.": "Você não tem uma assinatura familiar.",
          "User not found.": "Usuário não encontrado.",
          "You are not part of a family subscription.": "Você não faz parte de uma assinatura familiar.",
          "Error adding family member.": "Erro ao adicionar membro da família.",
          "Error fetching family info.": "Erro ao buscar informações da assinatura familiar.",
          "Error leaving family subscription.": "Erro ao sair da assinatura familiar.",
          "Error removing family member.": "Erro ao remover membro da família.",
          "You have left the family subscription.": "Você saiu da assinatura familiar.",
          "User added to the family subscription.": "Usuário adicionado à assinatura familiar.",
          "User removed from the family subscription.": "Usuário removido da assinatura familiar.",
          "Add Member": "Adicionar membro",
          "Family head": "Responsável pela assinatura",

          "SubscriptionOptions purchase for": "Comprar por {{price}}",
          "Cancel anytime.": "Cancele a qualquer momento.",
          "One-time payment. Access forever.": "Pagamento único. Acesso para sempre.",
          "Product name monthly": "Assinatura premium mensal",
          "Product name yearly": "Assinatura premium anual",
          "Product name forever": "Premium para sempre",
          "Something went wrong": "Ops... Algo deu errado...",
          "Reload page": "Recarregar página",

          "footer.privacy": "Política de privacidade",
          "footer.terms": "Termos de uso",
          "footer.contact-us": "Fale conosco",

          "Manage subscription title": "Gerenciar assinatura",
          "Manage subscription next payment": "Data do próximo pagamento:",
          "Manage subscription subscription status": "Status da assinatura:",
          "Manage subscription subscription status Active": "Ativa",
          "Manage subscription subscription status not Active": "Inativa",
          "Manage subscription next payment price": "Valor do pagamento:",
          "Manage subscription cancel": "Cancelar assinatura",
          "Manage subscription cancel confirmation text": "Tem certeza de que deseja cancelar a assinatura? O acesso aos recursos Premium será perdido imediatamente após o cancelamento.",
          "Manage subscription cancel confirmation title": "Confirmar cancelamento de assinatura",
          "Manage subscription cancel result success": "Assinatura cancelada",
          "Manage subscription cancel result fail": "Erro ao cancelar assinatura: {{error}}"
        

        }
      },
      "ro": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Probleme cu plățile pe Google Play",
            "description1": "Din cauza modificărilor aduse politicilor Google Play, suntem obligați să schimbăm jurisdicția pentru a continua utilizarea sistemului de plată Google Play. Deoarece Google nu a oferit suficient timp pentru aceasta, nu am avut suficient timp pentru a face procesul de tranziție lin pentru utilizatorii noștri. Prin urmare, începând cu <b>26 decembrie 2024</b>, plățile prin sistemul de plată Google Play nu vor funcționa pentru o perioadă. De asemenea, toate abonamentele existente create înainte de această dată și ale căror perioade plătite au expirat nu pot fi reînnoite până când plățile prin Google Play nu vor fi reluate.",
            "description2": "În prezent, lucrăm pentru <b>reluarea plăților prin Google Play cât mai curând posibil</b>. Cu toate acestea, în această problemă, multe lucruri nu depind de noi, așa că va dura ceva timp. Mulțumim pentru înțelegere.",
            "description3": "Dacă aveți întrebări sau probleme, vă rugăm să ne contactați prin e-mail la <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Cont",
          "Providers": "Metode de autentificare",
          "Logout": "Deconectare",
          "Enter promo title": "Coduri promoționale",
          "Enter promo placeholder": "Introduceți codul promoțional",
          "Enter promo button": "Introduceți",
          "Promocode activated title": "Cod promoțional activat",
          "Promocode activated you received": "Ați primit:",
          "Promocode activated you received Premium until": "Abonament Premium până la {{date}}",
          "Promocode not activated title": "Eroare la activarea codului promoțional",
          "Promocode activation error unknown": "Eroare necunoscută",
          "Promocode activation error not found": "Codul promoțional nu a fost găsit",
          "Promocode activation error already used": "Codul promoțional a fost deja folosit",
          "Promocode activation error you already used": "Ați folosit deja acest cod promoțional",
          "Login methods title": "Metode de autentificare",
          "Login methods no data": "Nu există metode de autentificare",
          "Login methods add method": "Adăugați metodă de autentificare",
          "Active subscription title": "Abonamente active",
          "Active subscription active untill": "Următoarea plată {{date}}",
          "Active subscription manage subscription": "Gestionați abonamentul",
          "Active subscription no subscriptions": "Nu există abonamente active",
          "Active subscription per month": "Pe lună",
          "Active subscription per year": "Pe an",
          "Active subscription forever": "Pentru totdeauna",
          "Active subscription subscription": "Abonament",
          "Active subscription subscription title": "Abonament Premium ({{type}})",
          "Active subscription no ads": "Fără reclame",
          "Active subscription one-time purchase": "Achiziție unică",
          "Active subscription promocode": "Cod promoțional",
          "SubscriptionOptions title": "Obțineți Premium",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "{{price}} pe lună",
          "SubscriptionOptions price yearly": "{{price}} pe an",
          "SubscriptionOptions price forever": "{{price}} pentru totdeauna",
          "SubscriptionOptions monthly subscription": "Abonament lunar",
          "SubscriptionOptions yearly subscription": "Abonament anual",
          "SubscriptionOptions forever subscription": "Achiziție unică",
          "SubscriptionOptions continue button": "Continuare",
          "AuthMethods_welcome_title": "Bun venit la StudyGe",
          "AuthMethods_sign_in_google": "Autentificare cu Google",
          "AuthMethods_sign_in_apple": "Autentificare cu Apple",
          "AuthMethods_sign_in_email": "Autentificare cu Email",
          "AuthMethods_or": "SAU",
          "AuthMethods_email_placeholder": "Adresă de email",
          "AuthMethods_footer_text": "Continuând, sunteți de acord cu următoarele termeni:",
          "AuthMethods_terms_of_use": "Termeni de utilizare",
          "AuthMethods_and": "și",
          "AuthMethods_privacy_policy": "Politica de confidențialitate",
          "AuthMethods_footer_text_end": "— și confirmați că le-ați citit.",
          "AuthMethods_error": {
            "title": "A apărut o eroare",
            "invalid_email": "Email introdus incorect",
            "invalid_code": "Cod invalid. Încercați din nou.",
            "auth_conflict": "Această metodă de autentificare este deja asociată cu un cont",
            "auth_used": "Această metodă de autentificare a fost deja folosită de un alt utilizator",
            "auth_not_found": "Utilizatorul nu a fost găsit",
            "auth_access": "Eroare de acces",
            "auth_failed": "Eroare de autentificare. Verificați datele introduse.",
            "auth_conflict": "Această metodă de autentificare este deja asociată contului dvs. sau unui alt cont.",
            "auth_invalid_token": "Tokenul de acces furnizat nu este valid.",
            "auth_invalid_data": "Serverul a primit date incorecte. Încercați din nou.",
            "auth_server_error": "A apărut o eroare necunoscută pe server. Încercați mai târziu.",
            "unknown": "Eroare necunoscută"
          },
          "VerificationCodeInput": {
            "title": "Cod de verificare",
            "description": "Un mesaj cu codul de verificare a fost trimis pe dispozitivele dvs. Introduceți codul pentru a continua.",
            "resend_text": "Nu ați primit codul?",
            "resend_button": "Retrimiteți",
            "resend_timer": "Retrimiterea disponibilă în ({{seconds}}s)",
            "back_button": "Înapoi"
          },
          "Modal close button": "Închideți",
          "Access to premium subscription in the app": "Acces la toate funcțiile Premium, statistici și eliminarea reclamelor în aplicație",
          "Family Subscription": "Abonament de familie",
          "Invite Member": "Invită membru",
          "Enter member ID": "Introduceți ID-ul membrului",
          "No family subscription found.": "Nu s-a găsit niciun abonament de familie.",
          "No family members added yet.": "Nu au fost adăugați membri ai familiei.",
          "Remove": "Elimină",
          "Leave Family Subscription": "Părăsiți abonamentul de familie",
          "Your premium access is expired, contact to your subscriptions head": "Accesul dvs. premium a expirat, contactați administratorul abonamentului",
          "Success": "Succes",
          "You have successfully left the family subscription.": "Ați părăsit cu succes abonamentul de familie.",
          "Error": "Eroare",
          "Failed to fetch family subscription info.": "Nu s-a reușit obținerea informațiilor despre abonamentul de familie.",
          "Failed to add family member. Please try again.": "Adăugarea membrului familiei a eșuat. Vă rugăm să încercați din nou.",
          "Failed to remove family member. Please try again.": "Eliminarea membrului familiei a eșuat. Vă rugăm să încercați din nou.",
          "Failed to leave the family subscription.": "Părăsirea abonamentului de familie a eșuat.",
          "Only users with premium access can be the head of a family subscription.": "Doar utilizatorii cu acces premium pot fi șeful unui abonament de familie.",
          "You are already a member of another family subscription and cannot invite members.": "Ești deja membru al altui abonament de familie și nu poți invita membri.",
          "Invitee not found.": "Utilizatorul pe care încerci să-l inviți nu a fost găsit.",
          "Invitee is already part of another family subscription.": "Utilizatorul face deja parte dintr-un alt abonament de familie.",
          "No available slots left in your family subscription.": "Nu mai există sloturi disponibile în abonamentul tău de familie.",
          "No family subscription found.": "Nu s-a găsit niciun abonament de familie.",
          "User is not a member of your family subscription.": "Utilizatorul nu este membru al abonamentului tău de familie.",
          "You do not have a family subscription.": "Nu ai un abonament de familie.",
          "User not found.": "Utilizatorul nu a fost găsit.",
          "You are not part of a family subscription.": "Nu faci parte dintr-un abonament de familie.",
          "Error adding family member.": "Eroare la adăugarea membrului familiei.",
          "Error fetching family info.": "Eroare la obținerea informațiilor despre abonamentul de familie.",
          "Error leaving family subscription.": "Eroare la părăsirea abonamentului de familie.",
          "Error removing family member.": "Eroare la eliminarea membrului familiei.",
          "You have left the family subscription.": "Ai părăsit abonamentul de familie.",
          "User added to the family subscription.": "Utilizatorul a fost adăugat la abonamentul de familie.",
          "User removed from the family subscription.": "Utilizatorul a fost eliminat din abonamentul de familie.",
          "Add Member": "Adaugă membru",
          "Family head": "Șeful abonamentului",

          "SubscriptionOptions purchase for": "Cumpără pentru {{price}}",
          "Cancel anytime.": "Anulează oricând.",
          "One-time payment. Access forever.": "Plată unică. Acces pentru totdeauna.",
          "Product name monthly": "Abonament premium lunar",
          "Product name yearly": "Abonament premium anual",
          "Product name forever": "Premium pe viață",

          "Something went wrong": "Ups... Ceva a mers prost...",
          "Reload page": "Reîncarcă pagina",

          "footer.privacy": "Politica de confidențialitate",
          "footer.terms": "Termeni de utilizare",
          "footer.contact-us": "Contactează-ne",

          "Manage subscription title": "Gestiona abonamentul",
          "Manage subscription next payment": "Data următoarei plăți:",
          "Manage subscription subscription status": "Starea abonamentului:",
          "Manage subscription subscription status Active": "Activ",
          "Manage subscription subscription status not Active": "Inactiv",
          "Manage subscription next payment price": "Suma plății:",
          "Manage subscription cancel": "Anulează abonamentul",
          "Manage subscription cancel confirmation text": "Ești sigur că vrei să anulezi abonamentul? Accesul la funcțiile Premium va fi pierdut imediat după anulare.",
          "Manage subscription cancel confirmation title": "Confirmă anularea abonamentului",
          "Manage subscription cancel result success": "Abonament anulat",
          "Manage subscription cancel result fail": "Eroare la anularea abonamentului: {{error}}"
        

        }
      },
      "tr": {
        "translation": {
          "googlePlayPurchasingIssues": {
            "title": "Google Play Ödeme Sorunları",
            "description1": "Google Play politikalarındaki değişiklikler nedeniyle, Google Play ödeme sistemini kullanmaya devam edebilmek için yetki alanını değiştirmek zorunda kaldık. Google bunun için yeterli zaman sağlamadığından, kullanıcılarımız için geçiş sürecini sorunsuz hale getirecek yeterli zamanımız olmadı. Bu nedenle, <b>26 Aralık 2024</b> tarihinden itibaren Google Play ödeme sistemi üzerinden yapılan ödemeler bir süre boyunca çalışmayacaktır. Ayrıca, bu tarihten önce oluşturulan ve ödenmiş süresi sona eren tüm mevcut abonelikler, Google Play ödemeleri yeniden başlayana kadar yenilenemez.",
            "description2": "Şu anda <b>Google Play ödemelerini mümkün olan en kısa sürede yeniden başlatmak</b> için çalışıyoruz. Ancak, bu konuda bizim kontrolümüz dışında birçok faktör olduğundan biraz zaman alacaktır. Anlayışınız için teşekkür ederiz.",
            "description3": "Herhangi bir sorunuz veya sorununuz varsa, lütfen bize şu e-posta adresinden ulaşın: <a href=\"mailto:milev.studio@gmail.com\">milev.studio@gmail.com</a>"
        },
          "Account Data": "Hesap Bilgileri",
          "Providers": "Giriş Yöntemleri",
          "Logout": "Çıkış Yap",
          "Enter promo title": "Promosyon Kodları",
          "Enter promo placeholder": "Promosyon kodunu girin",
          "Enter promo button": "Girin",
          "Promocode activated title": "Promosyon Kodu Etkinleştirildi",
          "Promocode activated you received": "Aldınız:",
          "Promocode activated you received Premium until": "Premium aboneliği {{date}} tarihine kadar aldınız",
          "Promocode not activated title": "Promosyon Kodu Etkinleştirilemedi",
          "Promocode activation error unknown": "Bilinmeyen hata",
          "Promocode activation error not found": "Promosyon kodu bulunamadı",
          "Promocode activation error already used": "Promosyon kodu zaten kullanılmış",
          "Promocode activation error you already used": "Bu promosyon kodunu zaten kullandınız",
          "Login methods title": "Giriş Yöntemleri",
          "Login methods no data": "Giriş yöntemi yok",
          "Login methods add method": "Giriş yöntemi ekle",
          "Active subscription title": "Aktif Abonelikler",
          "Active subscription active untill": "Sonraki ödeme {{date}}",
          "Active subscription manage subscription": "Aboneliği Yönet",
          "Active subscription no subscriptions": "Aktif abonelik yok",
          "Active subscription per month": "Aylık",
          "Active subscription per year": "Yıllık",
          "Active subscription forever": "Sürekli",
          "Active subscription subscription": "Abonelik",
          "Active subscription subscription title": "Premium Abonelik ({{type}})",
          "Active subscription no ads": "Reklamsız",
          "Active subscription one-time purchase": "Tek Seferlik Satın Alma",
          "Active subscription promocode": "Promosyon Kodu",
          "SubscriptionOptions title": "Premium Abonelik Al",
          "SubscriptionOptions available for russia": "",
          "SubscriptionOptions price monthly": "Aylık {{price}}",
          "SubscriptionOptions price yearly": "Yıllık {{price}}",
          "SubscriptionOptions price forever": "Süresiz {{price}}",
          "SubscriptionOptions monthly subscription": "Aylık Abonelik",
          "SubscriptionOptions yearly subscription": "Yıllık Abonelik",
          "SubscriptionOptions forever subscription": "Tek Seferlik Satın Alma",
          "SubscriptionOptions continue button": "Devam Et",
          "AuthMethods_welcome_title": "StudyGe'ye Hoş Geldiniz",
          "AuthMethods_sign_in_google": "Google ile Giriş Yap",
          "AuthMethods_sign_in_apple": "Apple ile Giriş Yap",
          "AuthMethods_sign_in_email": "E-posta ile Giriş Yap",
          "AuthMethods_or": "VEYA",
          "AuthMethods_email_placeholder": "E-posta Adresi",
          "AuthMethods_footer_text": "Devam ederek aşağıdaki şartları kabul etmiş olursunuz:",
          "AuthMethods_terms_of_use": "Hizmet Koşulları",
          "AuthMethods_and": "ve",
          "AuthMethods_privacy_policy": "Gizlilik Politikası",
          "AuthMethods_footer_text_end": "— ve okuduğunuzu onaylarsınız.",
          "AuthMethods_error": {
            "title": "Bir Hata Oluştu",
            "invalid_email": "Geçersiz e-posta adresi",
            "invalid_code": "Geçersiz kod. Tekrar deneyin.",
            "auth_conflict": "Bu giriş yöntemi zaten hesaba bağlı",
            "auth_used": "Bu giriş yöntemi başka bir kullanıcı tarafından kullanıldı",
            "auth_not_found": "Kullanıcı bulunamadı",
            "auth_access": "Erişim hatası",
            "auth_failed": "Kimlik doğrulama hatası. Girilen bilgileri kontrol edin.",
            "auth_conflict": "Bu giriş yöntemi zaten sizin veya başka bir kullanıcının hesabına bağlı.",
            "auth_invalid_token": "Sağlanan erişim belirteci geçersiz.",
            "auth_invalid_data": "Sunucu geçersiz veriler aldı. Tekrar deneyin.",
            "auth_server_error": "Sunucuda bilinmeyen bir hata oluştu. Daha sonra tekrar deneyin.",
            "unknown": "Bilinmeyen hata"
          },
          "VerificationCodeInput": {
            "title": "Doğrulama Kodu",
            "description": "Doğrulama kodu içeren mesaj cihazlarınıza gönderildi. Devam etmek için kodu girin.",
            "resend_text": "Kodu almadınız mı?",
            "resend_button": "Tekrar Gönder",
            "resend_timer": "Tekrar gönderme ({{seconds}}sn) içinde mümkün",
            "back_button": "Geri"
          },
          "Modal close button": "Kapat",
          "Access to premium subscription in the app": "Uygulamadaki tüm Premium özelliklere, istatistiklere ve reklam kaldırma işlemlerine erişim",
          "Family Subscription": "Aile Aboneliği",
          "Invite Member": "Üye Davet Et",
          "Enter member ID": "Üye Kimliğini Girin",
          "No family subscription found.": "Aile aboneliği bulunamadı.",
          "No family members added yet.": "Henüz aile üyeleri eklenmedi.",
          "Remove": "Kaldır",
          "Leave Family Subscription": "Aile Aboneliğinden Çık",
          "Your premium access is expired, contact to your subscriptions head": "Premium erişiminiz sona erdi, abonelik yöneticisiyle iletişime geçin",
          "Success": "Başarı",
          "You have successfully left the family subscription.": "Aile aboneliğinden başarıyla çıktınız.",
          "Error": "Hata",
          "Failed to fetch family subscription info.": "Aile aboneliği bilgileri alınamadı.",
          "Failed to add family member. Please try again.": "Aile üyesi eklenemedi. Lütfen tekrar deneyin.",
          "Failed to remove family member. Please try again.": "Aile üyesi kaldırılamadı. Lütfen tekrar deneyin.",
          "Failed to leave the family subscription.": "Aile aboneliğinden çıkılamadı.",
          "Only users with premium access can be the head of a family subscription.": "Yalnızca premium erişimi olan kullanıcılar aile aboneliğinin yöneticisi olabilir.",
          "You are already a member of another family subscription and cannot invite members.": "Zaten başka bir aile aboneliğinin üyesisiniz ve üye davet edemezsiniz.",
          "Invitee not found.": "Davet etmek istediğiniz kullanıcı bulunamadı.",
          "Invitee is already part of another family subscription.": "Kullanıcı zaten başka bir aile aboneliğine üye.",
          "No available slots left in your family subscription.": "Aile aboneliğinizde uygun boşluk kalmadı.",
          "No family subscription found.": "Aile aboneliği bulunamadı.",
          "User is not a member of your family subscription.": "Kullanıcı aile aboneliğinizin bir üyesi değil.",
          "You do not have a family subscription.": "Bir aile aboneliğiniz yok.",
          "User not found.": "Kullanıcı bulunamadı.",
          "You are not part of a family subscription.": "Bir aile aboneliğinin parçası değilsiniz.",
          "Error adding family member.": "Aile üyesi eklenirken hata oluştu.",
          "Error fetching family info.": "Aile aboneliği bilgileri alınırken hata oluştu.",
          "Error leaving family subscription.": "Aile aboneliğinden ayrılırken hata oluştu.",
          "Error removing family member.": "Aile üyesi kaldırılırken hata oluştu.",
          "You have left the family subscription.": "Aile aboneliğinden ayrıldınız.",
          "User added to the family subscription.": "Kullanıcı aile aboneliğine eklendi.",
          "User removed from the family subscription.": "Kullanıcı aile aboneliğinden çıkarıldı.",
          "Add Member": "Üye Ekle",
          "Family head": "Abonelik Yöneticisi",


          "SubscriptionOptions purchase for": "{{price}} için satın al",
          "Cancel anytime.": "İstediğiniz zaman iptal edin.",
          "One-time payment. Access forever.": "Tek seferlik ödeme. Sonsuz erişim.",
          "Product name monthly": "Aylık premium abonelik",
          "Product name yearly": "Yıllık premium abonelik",
          "Product name forever": "Sonsuza kadar premium",

          "Something went wrong": "Hata... Bir şeyler ters gitti...",
          "Reload page": "Sayfayı yeniden yükle",

          "footer.privacy": "Gizlilik politikası",
          "footer.terms": "Kullanım şartları",
          "footer.contact-us": "Bize ulaşın",

          "Manage subscription title": "Aboneliği yönet",
          "Manage subscription next payment": "Sonraki ödeme tarihi:",
          "Manage subscription subscription status": "Abonelik durumu:",
          "Manage subscription subscription status Active": "Aktif",
          "Manage subscription subscription status not Active": "Aktif değil",
          "Manage subscription next payment price": "Ödeme tutarı:",
          "Manage subscription cancel": "Aboneliği iptal et",
          "Manage subscription cancel confirmation text": "Aboneliği iptal etmek istediğinizden emin misiniz? Abonelik iptali sonrasında Premium özelliklere erişim anında kaybedilecektir.",
          "Manage subscription cancel confirmation title": "Aboneliğin iptalini onaylayın",
          "Manage subscription cancel result success": "Abonelik iptal edildi",
          "Manage subscription cancel result fail": "Abonelik iptalinde hata: {{error}}"
       

        }
      }
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
