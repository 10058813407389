import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import './AlternativePurchasing.css';

const AlternativePurchasing = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handlePurchasingRedirect = () => {
        navigate('/profile');
    };

    return (
        <div className="alternative-purchasing-container">
            <div className="alternative-purchasing-content">
                <h1 className="alternative-purchasing-title">{t('googlePlayPurchasingIssues.title')}</h1>
                <p className="alternative-purchasing-text" dangerouslySetInnerHTML={{ __html: t('googlePlayPurchasingIssues.description1') }}></p>
                <p className="alternative-purchasing-text" dangerouslySetInnerHTML={{ __html: t('googlePlayPurchasingIssues.description2') }}></p>
                <p className="alternative-purchasing-text" dangerouslySetInnerHTML={{ __html: t('googlePlayPurchasingIssues.description3') }}></p>
            </div>
        </div>
    );
};

export default AlternativePurchasing;
